import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Typography,
  Modal,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Checkbox,
  Button,
} from "@mui/material";
import {
  replenishDamagedReverseOrder,
  updateDamagedReverseOrders,
  updateReconProcessedAt,
} from "../../actions/reverseDeliveryActions";
import { showFullScreenLoader } from "../../actions/loaderActions";

export const ReverseDamagedModal = ({
  open,
  setSelectDamagedModal,
  orderProducts,
  orderId,
  package_status,
  setSearchValue,
  setSearchAwbValue,
}) => {
  const dispatch = useDispatch();
  const [openUp, setOpenUp] = useState(open);
  const [selectedOrders, setSelectedOrders] = useState([]);
  //   console.log(selectedOrders)
  const [updatedOrderProducts, setUpdatedOrderProducts] = useState([]);

  const { shopify_product_variants } = useSelector(
    (state) => state.shopifyDetails
  );
  const { stock } = useSelector((state) => state.inventoryDetails);

  useEffect(() => {
    if (stock.length > 0) {
      const productsWithImages = orderProducts.map((p) => {
        const found = stock.find((i) => i.SKU === p.sku);
        const url =
          found?.imageUrl ||
          "https://media.istockphoto.com/vectors/error-page-or-file-not-found-icon-vector-id924949200";
        return { ...p, url };
      });
      setUpdatedOrderProducts(productsWithImages);
    }
  }, [stock, orderProducts]);

  const handleClose = () => {
    setOpenUp(false);
    setSelectDamagedModal(false);
    setSelectedOrders([]);
    setSearchValue("");
    setSearchAwbValue("");
  };

  const handleSubmit = async () => {
    dispatch(showFullScreenLoader());
    const toBemarkedDamagedItems = selectedOrders.map((x) => ({
      ...x,
      damaged: true,
    }));

    const toBeUpdatedInDynamo = updatedOrderProducts.filter(
      (x) => !selectedOrders.includes(x)
    );

    const reconItems = [
      ...toBemarkedDamagedItems.map((o) => ({
        sku: o.sku,
        units: 1,
        originalSize: o.originalSize,
        variant_title: o.size,
        damaged: true,
      })),
      ...toBeUpdatedInDynamo.map((o) => ({
        sku: o.sku,
        originalSize: o.originalSize,
        units: 1,
        variant_title: o.size,
      })),
    ];
    const reconPayload = {
      fulfillment_id: orderId,
      line_items: reconItems,
    };
    console.log("Recon payload", reconPayload);
    // const data = await updateReconProcessedAt(reconPayload);
    // if (data === 500) {
    //   return;
    // }
    let updatedBodyShopify = [];
    const toBeUpdatedInShopify = toBeUpdatedInDynamo?.map((x) => {
      const filteredVariant = shopify_product_variants.filter(
        (item) =>
          item.sku === x.sku.split(".")[0] && item.title === x.originalSize
      );
      console.log(filteredVariant);
      if (filteredVariant)
        updatedBodyShopify.push({
          location_id: 65807843485,
          available_adjustment: 1,
          inventory_item_id: filteredVariant[0].inventory_item_id,
        });
      else return;
    });

    const toBeUpdatedBody = {
      id: orderId,
      order_items: [...toBeUpdatedInDynamo, ...toBemarkedDamagedItems],
      toBeUpdatedItems: toBeUpdatedInDynamo,
    };
    console.log(toBeUpdatedBody, package_status);
    console.log(updatedBodyShopify, package_status);

    dispatch(replenishDamagedReverseOrder(toBeUpdatedBody));
    // if (updatedBodyShopify.length > 0) {
    // dispatch(updateDamagedReverseOrders(updatedBodyShopify, toBeUpdatedBody));
    // } else {
    //   dispatch(replenishDamagedReverseOrder(toBeUpdatedBody));
    // }
    handleClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    background: "whitesmoke",
    textAlign: "center",
    maxHeight: "80vh",
    overflowY: "scroll",
    maxWidth: "90%",
  };

  return (
    <div>
      <Modal
        open={openUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3>Select Damaged Items</h3>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <>
                      <Checkbox
                        color="primary"
                        checked={selectedOrders.length > 0 ? true : false}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedOrders(updatedOrderProducts);
                          } else {
                            setSelectedOrders([]);
                          }
                        }}
                      />
                      {`${selectedOrders.length} Selected`}
                    </>
                  </TableCell>
                  <TableCell>Image</TableCell>
                  {/* <TableCell align="right">Name</TableCell> */}
                  <TableCell align="right">SKU</TableCell>
                  <TableCell align="right">Size</TableCell>
                  {/* <TableCell align="right">Quantity</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {updatedOrderProducts.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Checkbox
                        color="primary"
                        checked={
                          selectedOrders.filter((i) => i.sku === row.sku)
                            .length > 0
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedOrders([...selectedOrders, row]);
                          } else {
                            setSelectedOrders(
                              selectedOrders.filter(
                                (item) => item.sku !== row.sku
                              )
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Avatar
                        src={row.url}
                        sx={{
                          width: 100,
                          height: 100,
                          objectFit: "ro",
                        }}
                        variant="square"
                      />
                    </TableCell>
                    {/* <TableCell align="right">{row.name}</TableCell> */}
                    <TableCell align="right">{row.sku}</TableCell>
                    <TableCell align="right">{row.originalSize}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Button
            variant="contained"
            color="error"
            disabled={selectedOrders.length === 0}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
