import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import ProcessedQnty from "./ProcessedQnty";
import { Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  createProcessedHistory,
  updateLittleBoxProcessedQnty,
} from "../../../actions/POActions";
import { useDispatch, useSelector } from "react-redux";
import { addInventoryStock } from "../../../actions/inventoryActions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "80%",
  maxWidth: "95%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function ProcessedQntyModal({
  processedQnty,
  sizes,
  setSelectedProcessedQnty,
  open,
  setOpen,
}) {
  const inventory = useSelector((state) => state.inventoryDetails.stock);
  const dispatch = useDispatch();

  const handleClose = () => {
    setSelectedProcessedQnty({});
    setOpen(false);
  };
  const handlePO = async (qnty, vendorCode, toSendVendorCode, date) => {
    //cutting to stitching
    const newPOVendorValues = {
      ...processedQnty.POVendor,
      [vendorCode]: sizes.reduce((acc, size) => {
        acc[size] = processedQnty.POVendor[vendorCode][size] - qnty[size];
        return acc;
      }, {}),
    };
    const newPOValues = sizes.reduce((acc, size) => {
      const amt = Object.values(newPOVendorValues).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});
    //for cutting
    const newCuttingVendorValues = {
      ...processedQnty.cuttingVendor,
      [toSendVendorCode]: sizes.reduce((acc, size) => {
        acc[size] =
          processedQnty.cuttingVendor[toSendVendorCode][size] + qnty[size];
        return acc;
      }, {}),
    };
    const newCuttingValues = sizes.reduce((acc, size) => {
      const amt = Object.values(newCuttingVendorValues).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});

    const newProcessedQnty = {
      ...processedQnty,
      PO: newPOValues,
      cutting: newCuttingValues,
      POVendor: newPOVendorValues,
      cuttingVendor: newCuttingVendorValues,
    };
    const payload = {
      sku: newProcessedQnty.barcode,
      PO: newProcessedQnty.PO,
      cutting: newProcessedQnty.cutting,
      stitching: newProcessedQnty.stitching,
      finishing: newProcessedQnty.finishing,
      POVendor: newProcessedQnty.POVendor,
      cuttingVendor: newProcessedQnty.cuttingVendor,
      stitchingVendor: newProcessedQnty.stitchingVendor,
      finishingVendor: newProcessedQnty.finishingVendor,
    };
    //create history
    //change in po values, change in cutting values
    // const historyPayload = {
    //   sku: newProcessedQnty.barcode,
    //   oldPOVendor: processedQnty.POVendor,
    //   oldCuttingVendor: processedQnty.cuttingVendor,
    //   POVendor: newProcessedQnty.POVendor,
    //   cuttingVendor: newProcessedQnty.cuttingVendor,
    // };
    // await createProcessedHistory(historyPayload);
    dispatch(updateLittleBoxProcessedQnty(payload));
  };
  const handleCutting = (qnty, vendorCode, toSendVendorCode, date) => {
    //cutting to stitching
    //for cutting
    const newCuttingVendorValues = {
      ...processedQnty.cuttingVendor,
      [vendorCode]: sizes.reduce((acc, size) => {
        acc[size] = processedQnty.cuttingVendor[vendorCode][size] - qnty[size];
        return acc;
      }, {}),
    };
    const newCuttingValues = sizes.reduce((acc, size) => {
      const amt = Object.values(newCuttingVendorValues).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});
    //for stitching
    const newStitchingVendorValues = {
      ...processedQnty.stitchingVendor,
      [toSendVendorCode]: sizes.reduce((acc, size) => {
        acc[size] =
          processedQnty.stitchingVendor[toSendVendorCode][size] + qnty[size];
        return acc;
      }, {}),
    };
    const newStitchingValues = sizes.reduce((acc, size) => {
      const amt = Object.values(newStitchingVendorValues).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});
    const newProcessedQnty = {
      ...processedQnty,
      cutting: newCuttingValues,
      stitching: newStitchingValues,
      cuttingVendor: newCuttingVendorValues,
      stitchingVendor: newStitchingVendorValues,
    };
    const payload = {
      sku: newProcessedQnty.barcode,
      PO: newProcessedQnty.PO,
      cutting: newProcessedQnty.cutting,
      stitching: newProcessedQnty.stitching,
      finishing: newProcessedQnty.finishing,
      POVendor: newProcessedQnty.POVendor,
      cuttingVendor: newProcessedQnty.cuttingVendor,
      stitchingVendor: newProcessedQnty.stitchingVendor,
      finishingVendor: newProcessedQnty.finishingVendor,
    };
    dispatch(updateLittleBoxProcessedQnty(payload));
  };

  const handleStitching = (qnty, vendorCode, toSendVendorCode, date) => {
    //stitching to finishing
    //for stitching
    const newStitchingVendorValues = {
      ...processedQnty.stitchingVendor,
      [vendorCode]: sizes.reduce((acc, size) => {
        acc[size] =
          processedQnty.stitchingVendor[vendorCode][size] - qnty[size];
        return acc;
      }, {}),
    };
    const newStitchingValues = sizes.reduce((acc, size) => {
      const amt = Object.values(newStitchingVendorValues).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});
    //
    // for finishing
    const newFinishingVendorValues = {
      ...processedQnty.finishingVendor,
      [toSendVendorCode]: sizes.reduce((acc, size) => {
        acc[size] =
          processedQnty.finishingVendor[toSendVendorCode][size] + qnty[size];
        return acc;
      }, {}),
    };
    const newFinishingValues = sizes.reduce((acc, size) => {
      const amt = Object.values(newFinishingVendorValues).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});

    const newProcessedQnty = {
      ...processedQnty,
      stitching: newStitchingValues,
      finishing: newFinishingValues,
      stitchingVendor: newStitchingVendorValues,
      finishingVendor: newFinishingVendorValues,
    };
    const payload = {
      sku: newProcessedQnty.barcode,
      PO: newProcessedQnty.PO,
      cutting: newProcessedQnty.cutting,
      stitching: newProcessedQnty.stitching,
      finishing: newProcessedQnty.finishing,
      POVendor: newProcessedQnty.POVendor,
      cuttingVendor: newProcessedQnty.cuttingVendor,
      stitchingVendor: newProcessedQnty.stitchingVendor,
      finishingVendor: newProcessedQnty.finishingVendor,
    };
    dispatch(updateLittleBoxProcessedQnty(payload));
  };
  const handleFinishing = (qnty, vendorCode, date) => {
    const newFinishingVendorValues = {
      ...processedQnty.finishingVendor,
      [vendorCode]: sizes.reduce((acc, size) => {
        acc[size] =
          processedQnty.finishingVendor[vendorCode][size] - qnty[size];
        return acc;
      }, {}),
    };
    const newFinishingValues = sizes.reduce((acc, size) => {
      const amt = Object.values(newFinishingVendorValues).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});
    //add to stock

    const newProcessedQnty = {
      ...processedQnty,
      finishing: newFinishingValues,
      finishingVendor: newFinishingVendorValues,
    };

    const payload = {
      sku: newProcessedQnty.barcode,
      PO: newProcessedQnty.PO,
      cutting: newProcessedQnty.cutting,
      stitching: newProcessedQnty.stitching,
      finishing: newProcessedQnty.finishing,
      POVendor: newProcessedQnty.POVendor,
      cuttingVendor: newProcessedQnty.cuttingVendor,
      stitchingVendor: newProcessedQnty.stitchingVendor,
      finishingVendor: newProcessedQnty.finishingVendor,
    };
    const stockPayload = Object.keys(qnty).reduce((acc, s) => {
      acc.push({
        barcode: inventory.find(
          (i) => i.SKU === processedQnty.barcode && String(i.size) === String(s)
        )["barcode"],
        count: qnty[s],
      });
      return acc;
    }, []);
    dispatch(updateLittleBoxProcessedQnty(payload));
    dispatch(addInventoryStock(stockPayload));
  };
  const handleEdit = (keyname, vendorQnty) => {
    const qnty = sizes.reduce((acc, size) => {
      const amt = Object.values(vendorQnty).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});
    const vendorKeyName = `${keyname}Vendor`;
    const newProcessedQnty = {
      ...processedQnty,
      [keyname]: qnty,
      [vendorKeyName]: vendorQnty,
    };
    const payload = {
      sku: newProcessedQnty.barcode,
      PO: newProcessedQnty.PO,
      cutting: newProcessedQnty.cutting,
      stitching: newProcessedQnty.stitching,
      finishing: newProcessedQnty.finishing,
      POVendor: newProcessedQnty.POVendor,
      cuttingVendor: newProcessedQnty.cuttingVendor,
      stitchingVendor: newProcessedQnty.stitchingVendor,
      finishingVendor: newProcessedQnty.finishingVendor,
    };
    dispatch(updateLittleBoxProcessedQnty(payload));
  };
  const handleAddPo = (qnty, vendorCode) => {
    const newPOVendorValues = {
      ...processedQnty.POVendor,
      [vendorCode]: sizes.reduce((acc, size) => {
        acc[size] = processedQnty.POVendor[vendorCode][size] + qnty[size];
        return acc;
      }, {}),
    };
    const newPOValues = sizes.reduce((acc, size) => {
      const amt = Object.values(newPOVendorValues).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});
    const newProcessedQnty = {
      ...processedQnty,
      PO: newPOValues,
      POVendor: newPOVendorValues,
    };
    const payload = {
      sku: newProcessedQnty.barcode,
      PO: newProcessedQnty.PO,
      cutting: newProcessedQnty.cutting,
      stitching: newProcessedQnty.stitching,
      finishing: newProcessedQnty.finishing,
      POVendor: newProcessedQnty.POVendor,
      cuttingVendor: newProcessedQnty.cuttingVendor,
      stitchingVendor: newProcessedQnty.stitchingVendor,
      finishingVendor: newProcessedQnty.finishingVendor,
    };
    dispatch(updateLittleBoxProcessedQnty(payload));
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon
            onClick={handleClose}
            sx={{
              fontSize: 30,
              color: "red",
              cursor: "pointer",
              position: "absolute",
              top: 4,
              right: 2,
            }}
          />
          <Box
            sx={{
              overflow: "scroll",
              px: 3,
              height: "100%",
            }}
          >
            <h2 style={{ textAlign: "center" }}>{processedQnty.barcode}</h2>
            <ProcessedQnty
              sizes={sizes}
              keyName={"PO"}
              action={"send to cutting"}
              qnty={processedQnty.PO}
              vendorQnty={processedQnty.POVendor}
              handleClick={handlePO}
              handleEdit={handleEdit}
              handleAddPo={handleAddPo}
            />
            <Divider />
            <ProcessedQnty
              sizes={sizes}
              keyName={"cutting"}
              action={"send to stitching"}
              qnty={processedQnty.cutting}
              vendorQnty={processedQnty.cuttingVendor}
              handleClick={handleCutting}
              handleEdit={handleEdit}
            />
            <Divider />
            <ProcessedQnty
              sizes={sizes}
              keyName={"stitching"}
              action={"send to finishing"}
              qnty={processedQnty.stitching}
              vendorQnty={processedQnty.stitchingVendor}
              handleClick={handleStitching}
              handleEdit={handleEdit}
            />
            <Divider />
            <ProcessedQnty
              sizes={sizes}
              keyName={"finishing"}
              action={"stock add"}
              qnty={processedQnty.finishing}
              vendorQnty={processedQnty.finishingVendor}
              handleClick={handleFinishing}
              handleEdit={handleEdit}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
