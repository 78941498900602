import { lambdaFetch } from "../utils/awsUtils";
import { toast } from "react-toastify";

export const submitAwbSuccess = () => ({
  type: "submitAwbSuccess",
});

export const fetchScanSingleDateSuccess = (data) => ({
  type: "fetchScanSingleDateSuccess",
  payload: data,
});

export const getReconDetailsByFulfillmentId = async (fulfillment_id) => {
  try {
    let fetching = true;
    let ReconDetails = [];
    let LastEvaluatedKey = null;

    while (fetching) {
      const response = await lambdaFetch(
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/recon/getSingleRecon",
        {
          method: "POST",
          body: JSON.stringify({
            fulfillment_id: fulfillment_id,
            LastEvaluatedKey,
          }),
        }
      );
      const data = await response.json();
      // console.log(data, "data");
      if (data.statusCode === 200) {
        LastEvaluatedKey = data.LastEvaluatedKey;

        if (!LastEvaluatedKey) {
          fetching = false;
        }
        ReconDetails = [...ReconDetails, ...data.data];
      } else {
        toast.error("Enter Correct ID");
        return {
          statusCode: 500,
        };
      }
    }
    return {
      statusCode: 200,
      body: ReconDetails,
    };
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    return {
      statusCode: 500,
    };
  }
};

export const getAllReconDetailsByDateRange = async (start, end) => {
  try {
    let fetching = true;
    let ReconDetails = [];
    let LastEvaluatedKey = null;

    while (fetching) {
      const response = await lambdaFetch(
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/recon/getAllRecon",
        {
          method: "POST",
          body: JSON.stringify({
            starting_date: start,
            ending_date: end,
            LastEvaluatedKey,
          }),
        }
      );
      const data = await response.json();
      // console.log(data, "data");
      if (data.statusCode === 200) {
        LastEvaluatedKey = data.LastEvaluatedKey;

        if (!LastEvaluatedKey) {
          fetching = false;
        }
        ReconDetails = [...ReconDetails, ...data.data];
      } else {
        toast.error("Enter Correct Dates");
        return {
          statusCode: 500,
        };
      }
    }
    return {
      statusCode: 200,
      body: ReconDetails,
    };
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    return {
      statusCode: 500,
    };
  }
};
export const getAllReconDetailsBySingleDate = async (selected_date) => {
  try {
    let fetching = true;
    let ReconDetails = [];
    let LastEvaluatedKey = null;

    while (fetching) {
      const response = await lambdaFetch(
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/recon/getAllRecon",
        {
          method: "POST",
          body: JSON.stringify({
            selected_date: selected_date,
            LastEvaluatedKey,
          }),
        }
      );
      const data = await response.json();
      if (data.statusCode === 200) {
        LastEvaluatedKey = data.LastEvaluatedKey;

        if (!LastEvaluatedKey) {
          fetching = false;
        }
        ReconDetails = [...ReconDetails, ...data.data];
      } else {
        toast.error("Enter Correct Dates");
        return {
          statusCode: 500,
        };
      }
    }
    return {
      statusCode: 200,
      body: ReconDetails,
    };
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    return {
      statusCode: 500,
    };
  }
};

export const getOrderDetailsByFullfilmentId = async (idName, idNum) => {
  try {
    let fetching = true;
    let ReconDetails = [];
    let LastEvaluatedKey = null;

    while (fetching) {
      const response = await lambdaFetch(
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orderDetailsByF_id",
        {
          method: "POST",
          body: JSON.stringify({ ID: idName, value: idNum, LastEvaluatedKey }),
        }
      );
      const data = await response.json();
      // console.log(data, "data");
      if (data.statusCode === 200) {
        LastEvaluatedKey = data.LastEvaluatedKey;

        if (!LastEvaluatedKey) {
          fetching = false;
        }
        ReconDetails = [...ReconDetails, ...data.data];
      } else {
        toast.error("Enter Correct Dates");
        return {
          statusCode: 500,
        };
      }
    }
    return {
      statusCode: 200,
      body: ReconDetails,
    };
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    return {
      statusCode: 500,
    };
  }
};

export const getAllScanSingleDate = (day) => async (dispatch) => {
  try {
    let fetching = true;
    let InScanDetails = [];
    let LastEvaluatedKey = null;
    console.log("day", day);

    while (fetching) {
      const response = await lambdaFetch(
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/recon/getAllInscanBydate",
        {
          method: "POST",
          body: JSON.stringify({
            date: day,
            LastEvaluatedKey,
          }),
        }
      );
      const data = await response.json();
      console.log("order scaned in the that day", data);
      if (data.statusCode === 200) {
        LastEvaluatedKey = data.LastEvaluatedKey;

        if (!LastEvaluatedKey) {
          fetching = false;
        }
        InScanDetails = [...InScanDetails, ...data.body];
      } else {
        toast.error("Something went Wrong, Try again");
        return {
          statusCode: 500,
        };
      }
    }
    dispatch(fetchScanSingleDateSuccess(InScanDetails));
    return {
      statusCode: 200,
      body: InScanDetails,
    };
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    return {
      statusCode: 500,
    };
  }
};

export const submitAwbScan = (awb, timestamp) => async (dispatch) => {
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/recon/inscan";

  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ awb, timestamp }),
    });
    const data = await response.json();

    if (data.statusCode === 200) {
      toast.success("Updated Successfully");
      dispatch(submitAwbSuccess());
    } else if (data.statusCode === 404) {
      toast.error(data.body);
      return {
        statusCode: 404,
      };
    } else {
      toast.error(
        `Error Uploading data into database with statusCode ${data.statusCode}`,
        {
          theme: "colored",
        }
      );
      return {
        statusCode: 500,
      };
    }
    return {
      statusCode: 200,
    };
  } catch (err) {
    toast.error("Something Went Wrong With Connecting To Server", {
      theme: "colored",
    });
    return {
      statusCode: 500,
    };
  }
};
