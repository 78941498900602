/*
  `gateway` field in orders is deprecated. USE `payment_gateway_names[]` instead
*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OrderTakeoutTable from "./OrderTakeoutTable";
import {
  mapShopifyToShipRocketFormat,
  processingExcludedOrders,
  arrangeByDate,
  filterOutFulfilledLineItems,
  getDispatchableOrders,
} from "../../utils/orderTakeoutHelpers";
import { paymentTypes } from "../../utils/helperArrays";
// import { servicablePincodes } from "../../utils/helpers";
import {
  TextField,
  MenuItem,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import OrderNotTakeoutTable from "./OrderNotTakeoutTable";

const OrderTakeout = ({
  orderData,
  stock,
  inTransitOrders,
  pincodes,
  includePincode,
  includeStates,
  states,
}) => {
  const [allOrders, setAllOrders] = useState([]);
  const [updatedInventory, setUpdatedInventory] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [includeExclude, setIncludeExclude] = useState(false);
  const [excludeByTags, setExcludeByTags] = useState("hypd");

  useEffect(() => {
    if (
      orderData.length > 0 &&
      stock.length > 0 &&
      inTransitOrders.length > 0
    ) {
      console.log("orders shopify", orderData);
      console.log("Processing Orders", inTransitOrders.length);
      const newOrderArray = orderData
        .map((o) => ({
          ...o,
          line_items: o.line_items.filter((p) => p.fulfillable_quantity > 0),
          financial_status:
            o.financial_status === "partially_refunded" &&
            !o.payment_gateway_names.includes("Cash on Delivery (COD)") //DEPRICATED `gateway`
              ? "paid"
              : o.financial_status === "partially_refunded" &&
                o.payment_gateway_names.includes("Cash on Delivery (COD)")
              ? "pending"
              : o.financial_status,
          original_items: o.line_items,
          payment_method: o.payment_gateway_names.includes(
            "Cash on Delivery (COD)"
          )
            ? "COD"
            : "Prepaid",
        }))
        .filter((o) => {
          if (excludeByTags.replace(/\s/g, "") === "") {
            return !includeExclude;
          } else {
            return includeExclude
              ? !!o.tags.toLowerCase().includes(excludeByTags.toLowerCase())
              : !o.tags.toLowerCase().includes(excludeByTags.toLowerCase());
          }
        }) //Exclude hypd

        .map((o) => {
          if (
            o.payment_method === "Prepaid" &&
            parseFloat(o.total_outstanding) > 0
          ) {
            // Return exchange prepaid also pay shipping cost 99
            console.log("return/exchange shipping cost", o);
            return {
              ...o,
              payment_method: "COD",
            };
          } else {
            return o;
          }
        })
        .filter((o) =>
          paymentMethod === ""
            ? false
            : paymentMethod === "paid"
            ? o.payment_method === "Prepaid"
            : o.payment_method === "COD"
        )
        .map(mapShopifyToShipRocketFormat);

      const processingExcluded =
        paymentMethod === "paid"
          ? newOrderArray
          : processingExcludedOrders(newOrderArray, inTransitOrders);

      console.log("temo", processingExcluded);

      const removedFulfilledItems =
        filterOutFulfilledLineItems(processingExcluded);

      console.log("Eligible", removedFulfilledItems);

      const filteredByStates = includeStates
        ? removedFulfilledItems.filter(
            (o) =>
              states.includes(o.billing_state) ||
              states.includes(o.shipping_state)
          )
        : removedFulfilledItems;
      console.log("Eligible by states", filteredByStates);
      if (includePincode) {
        const servicableOrders = filteredByStates.filter((o) => {
          const found = pincodes.find((p) => p === o.shipping_pincode);
          return !!found;
        });

        const sortedOrders = arrangeByDate(servicableOrders);

        const { fulfillOrders, onlyUpdatedInventory } = getDispatchableOrders(
          [...sortedOrders].reverse(),
          stock
        );

        setAllOrders(fulfillOrders);
        setUpdatedInventory(onlyUpdatedInventory);
      } else {
        const sortedOrders = arrangeByDate(filteredByStates);

        const { fulfillOrders, onlyUpdatedInventory } = getDispatchableOrders(
          [...sortedOrders].reverse(),
          stock
        );

        setAllOrders(fulfillOrders);
        setUpdatedInventory(onlyUpdatedInventory);
      }
    }
  }, [
    includePincode,
    orderData,
    inTransitOrders,
    stock,
    paymentMethod,
    excludeByTags,
    includeExclude,
    includeStates,
    states,
    pincodes,
  ]);

  return (
    <div>
      {orderData.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "20px",
            gap: 20,
            flex: 1,
          }}
        >
          <TextField
            select
            label="Select Payment Method"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            variant="outlined"
            className="select"
            sx={{ width: "300px", bgcolor: "white" }}
            size="small"
          >
            {paymentTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label={`${
              includeExclude ? "Include only" : "Exclude"
            } orders by tags`}
            sx={{ width: "300px", bgcolor: "white" }}
            size="small"
            value={excludeByTags}
            onChange={(e) => setExcludeByTags(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle edit field"
                    onClick={() => setIncludeExclude(!includeExclude)}
                    edge="end"
                  >
                    {includeExclude ? (
                      <CreditScoreIcon color="primary" />
                    ) : (
                      <CreditCardOffIcon color="error" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
      <OrderTakeoutTable
        tableData={allOrders}
        updatedInventory={updatedInventory}
      />
    </div>
  );
};

export default OrderTakeout;
