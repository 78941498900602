import { Button, Paper, Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ManageRto = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2, // Space between items
        minHeight: "100vh", // Full-height container
        backgroundColor: "#f5f5f5", // Optional background color
      }}
    >
      <h2 style={{ textAlign: "center" }}>Manage RTO</h2>
      <Paper
        sx={{
          width: 300,
          height: 150,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 2,
          backgroundColor: "#ffffff",
          boxShadow: 3,
        }}
      >
        <Button
          variant="contained"
          size="large"
          onClick={() => navigate("/scanrto")}
        >
          Quick Scan
        </Button>
      </Paper>
      <Paper
        sx={{
          width: 300,
          height: 150,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 2,
          backgroundColor: "#ffffff",
          boxShadow: 3,
        }}
      >
        <Button
          variant="contained"
          size="large"
          onClick={() => navigate("/markdamaged")}
        >
          Mark Damaged
        </Button>
      </Paper>
    </Box>
  );
};

export default ManageRto;
