import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import ProcessedQnty from "./ProcessedQnty";
import { Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  createProcessedHistory,
  updateLittleBoxProcessedQnty,
} from "../../../actions/POActions";
import { useDispatch, useSelector } from "react-redux";
import { addInventoryStock } from "../../../actions/inventoryActions";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "80%",
  maxWidth: "95%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function ProcessedQntyModal({
  processedQnty,
  sizes,
  setSelectedProcessedQnty,
  open,
  setOpen,
  stage,
}) {
  const inventory = useSelector((state) => state.inventoryDetails.stock);
  const dispatch = useDispatch();

  const handleClose = () => {
    setSelectedProcessedQnty({});
    setOpen(false);
  };
  const handlePO = async (qnty, vendorCode, toSendVendorCode, date) => {
    const processedDate = `${date}T00:00:00+05:30`;
    //cutting to stitching
    const newPOVendorValues = {
      ...processedQnty.POVendor,
      [vendorCode]: sizes.reduce(
        (acc, size) => {
          acc[size] = processedQnty.POVendor[vendorCode][size] - qnty[size];
          return acc;
        },
        { updated_at: processedQnty.POVendor[vendorCode].updated_at }
      ),
    };
    const newPOValues = sizes.reduce((acc, size) => {
      const amt = Object.values(newPOVendorValues).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});
    //for cutting
    const newCuttingVendorValues = {
      ...processedQnty.cuttingVendor,
      [toSendVendorCode]: sizes.reduce(
        (acc, size) => {
          acc[size] =
            processedQnty.cuttingVendor[toSendVendorCode][size] + qnty[size];
          return acc;
        },
        { updated_at: processedDate }
      ),
    };
    const newCuttingValues = sizes.reduce((acc, size) => {
      const amt = Object.values(newCuttingVendorValues).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});

    const newProcessedQnty = {
      ...processedQnty,
      PO: newPOValues,
      cutting: newCuttingValues,
      POVendor: newPOVendorValues,
      cuttingVendor: newCuttingVendorValues,
    };
    const payload = {
      sku: newProcessedQnty.barcode,
      PO: newProcessedQnty.PO,
      cutting: newProcessedQnty.cutting,
      stitching: newProcessedQnty.stitching,
      finishing: newProcessedQnty.finishing,
      POVendor: newProcessedQnty.POVendor,
      cuttingVendor: newProcessedQnty.cuttingVendor,
      stitchingVendor: newProcessedQnty.stitchingVendor,
      finishingVendor: newProcessedQnty.finishingVendor,
    };
    // create history
    // change in po values, change in cutting values
    const historyPayload = {
      sku: newProcessedQnty.barcode,
      processed_at: processedDate,
      actionName: "PO to Cutting",
      sender: String(vendorCode),
      receiver: String(toSendVendorCode),
      oldPOVendor: processedQnty.POVendor,
      oldCuttingVendor: processedQnty.cuttingVendor,
      POVendor: newProcessedQnty.POVendor,
      cuttingVendor: newProcessedQnty.cuttingVendor,
    };
    await createProcessedHistory(historyPayload);
    dispatch(updateLittleBoxProcessedQnty(payload));
  };
  const handleCutting = async (qnty, vendorCode, toSendVendorCode, date) => {
    const processedDate = `${date}T00:00:00+05:30`;
    //cutting to stitching
    //for cutting
    const newCuttingVendorValues = {
      ...processedQnty.cuttingVendor,
      [vendorCode]: sizes.reduce(
        (acc, size) => {
          acc[size] =
            processedQnty.cuttingVendor[vendorCode][size] - qnty[size];
          return acc;
        },
        { updated_at: processedQnty.cuttingVendor[vendorCode].updated_at }
      ),
    };
    const newCuttingValues = sizes.reduce((acc, size) => {
      const amt = Object.values(newCuttingVendorValues).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});
    //for stitching
    const newStitchingVendorValues = {
      ...processedQnty.stitchingVendor,
      [toSendVendorCode]: sizes.reduce(
        (acc, size) => {
          acc[size] =
            processedQnty.stitchingVendor[toSendVendorCode][size] + qnty[size];
          return acc;
        },
        { updated_at: processedDate }
      ),
    };
    const newStitchingValues = sizes.reduce((acc, size) => {
      const amt = Object.values(newStitchingVendorValues).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});
    const newProcessedQnty = {
      ...processedQnty,
      cutting: newCuttingValues,
      stitching: newStitchingValues,
      cuttingVendor: newCuttingVendorValues,
      stitchingVendor: newStitchingVendorValues,
    };
    const payload = {
      sku: newProcessedQnty.barcode,
      PO: newProcessedQnty.PO,
      cutting: newProcessedQnty.cutting,
      stitching: newProcessedQnty.stitching,
      finishing: newProcessedQnty.finishing,
      POVendor: newProcessedQnty.POVendor,
      cuttingVendor: newProcessedQnty.cuttingVendor,
      stitchingVendor: newProcessedQnty.stitchingVendor,
      finishingVendor: newProcessedQnty.finishingVendor,
    };
    const historyPayload = {
      sku: newProcessedQnty.barcode,
      processed_at: processedDate,
      actionName: "Cutting to Stitching",
      sender: String(vendorCode),
      receiver: String(toSendVendorCode),
      oldCuttingVendor: processedQnty.cuttingVendor,
      oldStitchingVendor: processedQnty.stitchingVendor,
      cuttingVendor: newProcessedQnty.cuttingVendor,
      stitchingVendor: newProcessedQnty.stitchingVendor,
    };
    await createProcessedHistory(historyPayload);
    dispatch(updateLittleBoxProcessedQnty(payload));
  };

  const handleStitching = async (qnty, vendorCode, toSendVendorCode, date) => {
    const processedDate = `${date}T00:00:00+05:30`;
    //stitching to finishing
    //for stitching
    const newStitchingVendorValues = {
      ...processedQnty.stitchingVendor,
      [vendorCode]: sizes.reduce(
        (acc, size) => {
          acc[size] =
            processedQnty.stitchingVendor[vendorCode][size] - qnty[size];
          return acc;
        },
        { updated_at: processedQnty.stitchingVendor[vendorCode].updated_at }
      ),
    };
    const newStitchingValues = sizes.reduce((acc, size) => {
      const amt = Object.values(newStitchingVendorValues).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});
    //
    // for finishing
    const newFinishingVendorValues = {
      ...processedQnty.finishingVendor,
      [toSendVendorCode]: sizes.reduce(
        (acc, size) => {
          acc[size] =
            processedQnty.finishingVendor[toSendVendorCode][size] + qnty[size];
          return acc;
        },
        { updated_at: processedDate }
      ),
    };
    const newFinishingValues = sizes.reduce((acc, size) => {
      const amt = Object.values(newFinishingVendorValues).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});

    const newProcessedQnty = {
      ...processedQnty,
      stitching: newStitchingValues,
      finishing: newFinishingValues,
      stitchingVendor: newStitchingVendorValues,
      finishingVendor: newFinishingVendorValues,
    };
    const payload = {
      sku: newProcessedQnty.barcode,
      PO: newProcessedQnty.PO,
      cutting: newProcessedQnty.cutting,
      stitching: newProcessedQnty.stitching,
      finishing: newProcessedQnty.finishing,
      POVendor: newProcessedQnty.POVendor,
      cuttingVendor: newProcessedQnty.cuttingVendor,
      stitchingVendor: newProcessedQnty.stitchingVendor,
      finishingVendor: newProcessedQnty.finishingVendor,
    };
    //create history
    // Stitching to finishing
    const historyPayload = {
      sku: newProcessedQnty.barcode,
      processed_at: processedDate,
      actionName: "Stitching to Finishing",
      sender: String(vendorCode),
      receiver: String(toSendVendorCode),
      oldStitchingVendor: processedQnty.stitchingVendor,
      oldFinishingVendor: processedQnty.finishingVendor,
      stitchingVendor: newProcessedQnty.stitchingVendor,
      finishingVendor: newProcessedQnty.finishingVendor,
    };
    await createProcessedHistory(historyPayload);
    dispatch(updateLittleBoxProcessedQnty(payload));
  };
  const handleFinishing = async (qnty, vendorCode, date) => {
    const processedDate = `${date}T00:00:00+05:30`;
    const newFinishingVendorValues = {
      ...processedQnty.finishingVendor,
      [vendorCode]: sizes.reduce(
        (acc, size) => {
          acc[size] =
            processedQnty.finishingVendor[vendorCode][size] - qnty[size];
          return acc;
        },
        { updated_at: processedQnty.finishingVendor[vendorCode].updated_at }
      ),
    };
    const newFinishingValues = sizes.reduce((acc, size) => {
      const amt = Object.values(newFinishingVendorValues).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});
    //add to stock

    const newProcessedQnty = {
      ...processedQnty,
      finishing: newFinishingValues,
      finishingVendor: newFinishingVendorValues,
    };

    const payload = {
      sku: newProcessedQnty.barcode,
      PO: newProcessedQnty.PO,
      cutting: newProcessedQnty.cutting,
      stitching: newProcessedQnty.stitching,
      finishing: newProcessedQnty.finishing,
      POVendor: newProcessedQnty.POVendor,
      cuttingVendor: newProcessedQnty.cuttingVendor,
      stitchingVendor: newProcessedQnty.stitchingVendor,
      finishingVendor: newProcessedQnty.finishingVendor,
    };
    const stockPayload = Object.keys(qnty).reduce((acc, s) => {
      acc.push({
        barcode: inventory.find(
          (i) => i.SKU === processedQnty.barcode && String(i.size) === String(s)
        )["barcode"],
        count: qnty[s],
      });
      return acc;
    }, []);
    //create history
    //stock outward
    // Stitching to finishing
    const historyPayload = {
      sku: newProcessedQnty.barcode,
      processed_at: processedDate,
      actionName: "Stock Outward",
      sender: String(vendorCode),
      oldFinishingVendor: processedQnty.finishingVendor,
      finishingVendor: newProcessedQnty.finishingVendor,
    };
    await createProcessedHistory(historyPayload);
    dispatch(updateLittleBoxProcessedQnty(payload));
    dispatch(addInventoryStock(stockPayload));
  };
  const handleEdit = async (keyname, vendorQnty) => {
    const qnty = sizes.reduce((acc, size) => {
      const amt = Object.values(vendorQnty).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});
    const vendorKeyName = `${keyname}Vendor`;
    const newProcessedQnty = {
      ...processedQnty,
      [keyname]: qnty,
      [vendorKeyName]: vendorQnty,
    };
    const payload = {
      sku: newProcessedQnty.barcode,
      PO: newProcessedQnty.PO,
      cutting: newProcessedQnty.cutting,
      stitching: newProcessedQnty.stitching,
      finishing: newProcessedQnty.finishing,
      POVendor: newProcessedQnty.POVendor,
      cuttingVendor: newProcessedQnty.cuttingVendor,
      stitchingVendor: newProcessedQnty.stitchingVendor,
      finishingVendor: newProcessedQnty.finishingVendor,
    };
    //create history
    const date = new Date().toISOString().split("T")[0];
    const processedDate = `${date}T00:00:00+05:30`;
    const historyPayload = {
      sku: newProcessedQnty.barcode,
      processed_at: processedDate,
      actionName: "Edited",
      editedStageName: String(keyname),
      [`old${vendorKeyName}`]: processedQnty[vendorKeyName],
      [vendorKeyName]: newProcessedQnty[vendorKeyName],
    };
    await createProcessedHistory(historyPayload);
    dispatch(updateLittleBoxProcessedQnty(payload));
  };
  const handleAddPo = async (qnty, vendorCode, date) => {
    const processedDate = `${date}T00:00:00+05:30`;
    const newPOVendorValues = {
      ...processedQnty.POVendor,
      [vendorCode]: sizes.reduce(
        (acc, size) => {
          acc[size] = processedQnty.POVendor[vendorCode][size] + qnty[size];
          return acc;
        },
        { updated_at: processedDate }
      ),
    };
    const newPOValues = sizes.reduce((acc, size) => {
      const amt = Object.values(newPOVendorValues).reduce(
        (acc, q) => acc + q[size],
        0
      );
      acc[size] = amt;
      return acc;
    }, {});
    const newProcessedQnty = {
      ...processedQnty,
      PO: newPOValues,
      POVendor: newPOVendorValues,
    };
    const payload = {
      sku: newProcessedQnty.barcode,
      PO: newProcessedQnty.PO,
      cutting: newProcessedQnty.cutting,
      stitching: newProcessedQnty.stitching,
      finishing: newProcessedQnty.finishing,
      POVendor: newProcessedQnty.POVendor,
      cuttingVendor: newProcessedQnty.cuttingVendor,
      stitchingVendor: newProcessedQnty.stitchingVendor,
      finishingVendor: newProcessedQnty.finishingVendor,
    };
    //create history
    const historyPayload = {
      sku: newProcessedQnty.barcode,
      processed_at: processedDate,
      actionName: "PO added",
      receiver: String(vendorCode),
      oldPOVendor: processedQnty.POVendor,
      POVendor: newProcessedQnty.POVendor,
    };
    await createProcessedHistory(historyPayload);
    dispatch(updateLittleBoxProcessedQnty(payload));
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon
            onClick={handleClose}
            sx={{
              fontSize: 30,
              color: "red",
              cursor: "pointer",
              position: "absolute",
              top: 4,
              right: 2,
            }}
          />
          <Box
            sx={{
              overflow: "scroll",
              px: 3,
              height: "100%",
            }}
          >
            <h2 style={{ textAlign: "center" }}>{processedQnty.barcode}</h2>
            {(stage === "All" || stage === "PO") && (
              <React.Fragment>
                <ProcessedQnty
                  sizes={sizes}
                  keyName={"PO"}
                  action={"send to cutting"}
                  qnty={processedQnty.PO}
                  vendorQnty={processedQnty.POVendor}
                  handleClick={handlePO}
                  handleEdit={handleEdit}
                  handleAddPo={handleAddPo}
                  stage={stage}
                />
                <Divider />
              </React.Fragment>
            )}
            {(stage === "All" || stage === "cutting") && (
              <React.Fragment>
                <ProcessedQnty
                  sizes={sizes}
                  keyName={"cutting"}
                  action={"send to stitching"}
                  qnty={processedQnty.cutting}
                  vendorQnty={processedQnty.cuttingVendor}
                  handleClick={handleCutting}
                  handleEdit={handleEdit}
                  stage={stage}
                />
                <Divider />
              </React.Fragment>
            )}
            {(stage === "All" || stage === "stitching") && (
              <React.Fragment>
                <ProcessedQnty
                  sizes={sizes}
                  keyName={"stitching"}
                  action={"send to finishing"}
                  qnty={processedQnty.stitching}
                  vendorQnty={processedQnty.stitchingVendor}
                  handleClick={handleStitching}
                  handleEdit={handleEdit}
                  stage={stage}
                />
                <Divider />
              </React.Fragment>
            )}
            {(stage === "All" || stage === "finishing") && (
              <ProcessedQnty
                sizes={sizes}
                keyName={"finishing"}
                action={"stock add"}
                qnty={processedQnty.finishing}
                vendorQnty={processedQnty.finishingVendor}
                handleClick={handleFinishing}
                handleEdit={handleEdit}
                stage={stage}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
