import { skuTypes, comboSKUs } from "./helperArrays";
import { formatCurrency } from "./orderTakeoutHelpers";
export const getGstData = (tableData) => {
  console.log("table", tableData);
  const calculateGst = tableData.map((row) => {
    let clothTemp = [];
    let clothQuantity = [];
    let clothValue = [];
    let footwearTemp = [];
    let footwearQuantity = [];
    let footwearValue = [];
    if (row.line_items.length > 0) {
      row.line_items.forEach((item) => {
        const st = item.sku;
        const regex = new RegExp("([0-9]+)|([a-zA-Z]+)", "g");
        const splittedSKU = st.match(regex)[0];
        console.log("splitted sku", splittedSKU);
        const x = skuTypes.filter((x) => x.value === splittedSKU);
        // ***************************** CLOTHING GST ***********************************************************
        if (x[0]?.label === "Clothing") {
          clothValue.push(
            (parseFloat(item.selling_price) - parseFloat(item.discount)) *
              item.units
          );
          clothQuantity.push(item.units);
          clothTemp.push(
            (parseFloat(item.selling_price) - parseFloat(item.discount)) *
              item.units -
              ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units) /
                1.05
          );
        }
        // ***************************** FOOTWEAR GST ***********************************************************
        else if (x[0]?.label === "Footwear") {
          footwearValue.push(
            (parseFloat(item.selling_price) - parseFloat(item.discount)) *
              item.units
          );
          footwearQuantity.push(item.units);
          footwearTemp.push(
            (parseFloat(item.selling_price) - parseFloat(item.discount)) *
              item.units -
              ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units) /
                1.12
          );
        }
      });
    } else {
      row.products.forEach((item) => {
        const st = item.channel_sku;
        const regex = new RegExp("([0-9]+)|([a-zA-Z]+)", "g");
        const splittedSKU = st.match(regex)[0];
        const x = skuTypes.filter((x) => x.value === splittedSKU);
        console.log("splitted sku", splittedSKU);
        if (x[0]?.label === "Clothing") {
          clothValue.push(parseFloat(item.price) * item.quantity);
          clothQuantity.push(item.quantity);
          clothTemp.push(
            parseFloat(item.price) * item.quantity -
              (parseFloat(item.price) * item.quantity) / 1.05
          );
        } else if (x[0]?.label === "Footwear") {
          footwearValue.push(parseFloat(item.price) * item.quantity);
          footwearQuantity.push(item.quantity);
          footwearTemp.push(
            parseFloat(item.price) * item.quantity -
              (parseFloat(item.price) * item.quantity) / 1.12
          );
        }
      });
    }

    const totalClothGst = formatCurrency(
      clothTemp.reduce((i, sum) => i + sum, 0)
    );
    const totalFootwearGst = formatCurrency(
      footwearTemp.reduce((i, sum) => i + sum, 0)
    );
    const totalClothValue = formatCurrency(
      clothValue.reduce((i, sum) => i + sum, 0)
    );
    const totalClothQuantity = clothQuantity.reduce((i, sum) => i + sum, 0);
    const totalFootwearValue = formatCurrency(
      footwearValue.reduce((i, sum) => i + sum, 0)
    );
    const totalFootwearQuantity = footwearQuantity.reduce(
      (i, sum) => i + sum,
      0
    );

    const shippingCharges =
      row.channel_order_id.split(".")[1] <= 1 ? row.others.shipping_charges : 0;
    const shippingGST =
      row.channel_order_id.split(".")[1] <= 1
        ? row.others.shipping_charges - row.others.shipping_charges / 1.18
        : 0;
    const shipmentGst = isNaN(formatCurrency(shippingGST))
      ? 0
      : formatCurrency(shippingGST);

    // if(totalClothValue)

    let sgst = 0;
    let cgst = 0;
    let igst = 0;

    const customer_address = row.customer_state;
    const totalGst = totalClothGst + totalFootwearGst;
    if (customer_address === "Delhi") {
      sgst = formatCurrency(totalGst / 2);
      cgst = formatCurrency(totalGst / 2);
      igst = 0;
    } else {
      sgst = 0;
      cgst = 0;
      igst = formatCurrency(totalGst);
    }
    // const clothGst = totalClothGst
    // const footwearGst = totalFootwearGst
    const gst = formatCurrency(totalGst + shipmentGst);

    const newArr = {
      order_number: row.channel_order_id,
      invoice_date: row.delivered_date,
      customer: row.customer_name,
      shipping_address: row.customer_state,
      awb: row.shipments[0].awb,
      courier: row.shipments[0].courier,
      payment: row.total,
      clothing_value: totalClothValue,
      clothing_quantity: totalClothQuantity,
      clothing_gst: totalClothGst,
      footwear_value: totalFootwearValue,
      footwear_quantity: totalFootwearQuantity,
      footwear_gst: totalFootwearGst,
      shipping_value: shippingCharges,
      shipping_gst: shipmentGst,
      sgst,
      cgst,
      igst,
      total_gst: gst,
    };
    return newArr;
  });
  return calculateGst;
};

export const getXBGstData = (tableData) => {
  console.log("tabel data", tableData);
  const calculateGst = tableData.map((row) => {
    // for delivered ******************************************************************************
    if (row.status === "Delivered") {
      let clothTemp = [];
      let clothTempFor12 = [];
      let clothTempFor5 = [];
      let clothQuantityFor12 = [];
      let clothQuantityFor5 = [];
      let clothValueFor12 = [];
      let clothValueFor5 = [];
      let footwearTemp = [];
      let footwearQuantity = [];
      let footwearValue = [];

      row.order_items?.forEach((item) => {
        const st = item.sku;
        const regex = new RegExp("([0-9]+)|([a-zA-Z]+)", "g");
        const splittedSKU = st.match(regex)[0];
        // console.log("splitted sku", splittedSKU);
        const x = skuTypes.filter((x) => x.value === splittedSKU);
        if (x[0]?.label === "Clothing") {
          const finalSellingPrice =
            parseFloat(item.selling_price) - parseFloat(item.discount);
          // if combo split value in two
          //if value above 999 12 % else 5%
          if (
            (comboSKUs.includes(st.split(".")[0]) &&
              finalSellingPrice / 2 > 999) ||
            (!comboSKUs.includes(st.split(".")[0]) && finalSellingPrice > 999)
          ) {
            clothValueFor12.push(
              (parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units
            );
            clothQuantityFor12.push(item.units);
            clothTempFor12.push(
              (parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units -
                ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
                  item.units) /
                  1.12
            );
            clothTemp.push(
              (parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units -
                ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
                  item.units) /
                  1.12
            );
          } else {
            clothValueFor5.push(
              (parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units
            );
            clothQuantityFor5.push(item.units);
            clothTempFor5.push(
              (parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units -
                ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
                  item.units) /
                  1.05
            );
            clothTemp.push(
              (parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units -
                ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
                  item.units) /
                  1.05
            );
          }
        } else if (x[0]?.label === "Footwear") {
          footwearValue.push(
            (parseFloat(item.selling_price) - parseFloat(item.discount)) *
              item.units
          );
          footwearQuantity.push(item.units);
          footwearTemp.push(
            (parseFloat(item.selling_price) - parseFloat(item.discount)) *
              item.units -
              ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units) /
                1.12
          );
        }
      });

      const totalFootwearGst = formatCurrency(
        footwearTemp.reduce((i, sum) => i + sum, 0)
      );
      const totalFootwearValue = formatCurrency(
        footwearValue.reduce((i, sum) => i + sum, 0)
      );
      const totalFootwearQuantity = footwearQuantity.reduce(
        (i, sum) => i + sum,
        0
      );
      const totalClothGst = formatCurrency(
        clothTemp.reduce((i, sum) => i + sum, 0)
      );
      const totalClothGstFor12 = formatCurrency(
        clothTempFor12.reduce((i, sum) => i + sum, 0)
      );
      const totalClothGstFor5 = formatCurrency(
        clothTempFor5.reduce((i, sum) => i + sum, 0)
      );
      const totalClothValueFor12 = formatCurrency(
        clothValueFor12.reduce((i, sum) => i + sum, 0)
      );
      const totalClothValueFor5 = formatCurrency(
        clothValueFor5.reduce((i, sum) => i + sum, 0)
      );
      const totalClothQuantityFor12 = clothQuantityFor12.reduce(
        (i, sum) => i + sum,
        0
      );
      const totalClothQuantityFor5 = clothQuantityFor5.reduce(
        (i, sum) => i + sum,
        0
      );

      const shippingCharges = row.shipping_charges;

      const shippingGST = row.shipping_charges - row.shipping_charges / 1.18;

      const shipmentGst = isNaN(formatCurrency(shippingGST))
        ? 0
        : formatCurrency(shippingGST);

      // if(totalClothValue)

      let sgst = 0;
      let cgst = 0;
      let igst = 0;

      const customer_address = row.billing_state;
      const totalGst = totalClothGst + totalFootwearGst;
      if (customer_address === "Delhi") {
        sgst = formatCurrency(totalGst / 2);
        cgst = formatCurrency(totalGst / 2);
        igst = 0;
      } else {
        sgst = 0;
        cgst = 0;
        igst = formatCurrency(totalGst);
      }
      // const clothGst = totalClothGst
      // const footwearGst = totalFootwearGst
      const gst = formatCurrency(totalGst + shipmentGst);

      const newArr = {
        order_number: row.fulfillment_id,
        status: row.status,
        invoice_date: row.delivered_date, //from excel
        customer: row.customer_name, //from excel
        shipping_address: row.billing_state,
        awb: row.awb, //from excel
        // courier : row.shipments[0].courier,
        payment: row.total,
        "clothing value(12%)": totalClothValueFor12,
        "clothing quantity(12%)": totalClothQuantityFor12,
        "clothing value(5%)": totalClothValueFor5,
        "clothing quantity(5%)": totalClothQuantityFor5,
        "clothing gst(12%)": totalClothGstFor12,
        "clothing gst(5%)": totalClothGstFor5,
        clothing_gst: totalClothGst,
        footwear_value: totalFootwearValue,
        footwear_quantity: totalFootwearQuantity,
        footwear_gst: totalFootwearGst,
        shipping_value: shippingCharges,
        shipping_gst: shipmentGst,
        sgst,
        cgst,
        igst,
        total_gst: gst,
      };
      return newArr;
    }
    //for non delivered ***************************************************************************
    else {
      let clothTemp = [];
      let clothTempFor12 = [];
      let clothTempFor5 = [];
      let clothQuantityFor12 = [];
      let clothQuantityFor5 = [];
      let clothValueFor12 = [];
      let clothValueFor5 = [];
      let clothQuantity = [];
      let clothValue = [];
      let footwearTemp = [];
      let footwearQuantity = [];
      let footwearValue = [];

      row.order_items?.forEach((item) => {
        const st = item.sku;
        const regex = new RegExp("([0-9]+)|([a-zA-Z]+)", "g");
        const splittedSKU = st.match(regex)[0];
        // console.log("splitted sku", splittedSKU);
        const x = skuTypes.filter((x) => x.value === splittedSKU);
        if (x[0]?.label === "Clothing") {
          const finalSellingPrice =
            parseFloat(item.selling_price) - parseFloat(item.discount);
          // if combo split value in two
          //if value  999 12 % else 5%
          if (
            (comboSKUs.includes(st.split(".")[0]) &&
              finalSellingPrice / 2 > 999) ||
            finalSellingPrice > 999
          ) {
            clothTemp.push(
              (parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units -
                ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
                  item.units) /
                  1.12
            );
            clothValueFor12.push(
              (parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units
            );
            clothQuantityFor12.push(item.units);
          } else {
            clothTemp.push(
              (parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units -
                ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
                  item.units) /
                  1.05
            );
            clothValueFor5.push(
              (parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units
            );
            clothQuantityFor5.push(item.units);
          }
        } else if (x[0]?.label === "Footwear") {
          footwearValue.push(
            (parseFloat(item.selling_price) - parseFloat(item.discount)) *
              item.units
          );
          footwearQuantity.push(item.units);
          footwearTemp.push(
            (parseFloat(item.selling_price) - parseFloat(item.discount)) *
              item.units -
              ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units) /
                1.12
          );
        }
      });

      const totalClothGst = formatCurrency(
        clothTemp.reduce((i, sum) => i + sum, 0)
      );
      const totalFootwearGst = formatCurrency(
        footwearTemp.reduce((i, sum) => i + sum, 0)
      );
      const totalClothValue = formatCurrency(
        clothValue.reduce((i, sum) => i + sum, 0)
      );
      const totalClothQuantity = clothQuantity.reduce((i, sum) => i + sum, 0);
      const totalClothValueFor12 = formatCurrency(
        clothValueFor12.reduce((i, sum) => i + sum, 0)
      );
      const totalClothValueFor5 = formatCurrency(
        clothValueFor5.reduce((i, sum) => i + sum, 0)
      );
      const totalClothQuantityFor12 = clothQuantityFor12.reduce(
        (i, sum) => i + sum,
        0
      );
      const totalClothQuantityFor5 = clothQuantityFor5.reduce(
        (i, sum) => i + sum,
        0
      );
      const totalFootwearValue = formatCurrency(
        footwearValue.reduce((i, sum) => i + sum, 0)
      );
      const totalFootwearQuantity = footwearQuantity.reduce(
        (i, sum) => i + sum,
        0
      );

      const shippingCharges = row.shipping_charges;

      const shippingGST = row.shipping_charges - row.shipping_charges / 1.18;
      const shipmentGst = isNaN(formatCurrency(shippingGST))
        ? 0
        : formatCurrency(shippingGST);

      // if(totalClothValue)

      let sgst = 0;
      let cgst = 0;
      let igst = 0;

      const customer_address = row.billing_state;
      const totalGst = totalClothGst + totalFootwearGst;
      if (customer_address === "Delhi") {
        sgst = formatCurrency(totalGst / 2);
        cgst = formatCurrency(totalGst / 2);
        igst = 0;
      } else {
        sgst = 0;
        cgst = 0;
        igst = formatCurrency(totalGst);
      }

      const gst = formatCurrency(totalGst + shipmentGst);

      const newArr = {
        order_number: row.fulfillment_id,
        status: row.status,
        invoice_date: row.delivered_date, //from excel
        customer: row.customer_name, //from excel
        shipping_address: row.billing_state,
        awb: row.awb, //from excel
        // courier : row.shipments[0].courier,
        payment: row.total,
        clothing_value: totalClothValue,
        clothing_quantity: totalClothQuantity,
        "clothing value(12%)": totalClothValueFor12,
        "clothing quantity(12%)": totalClothQuantityFor12,
        "clothing value(5%)": totalClothValueFor5,
        "clothing quantity(5%)": totalClothQuantityFor5,
        "clothing gst(12%)": 0,
        "clothing gst(5%)": 0,
        clothing_gst: 0,
        footwear_value: totalFootwearValue,
        footwear_quantity: totalFootwearQuantity,
        footwear_gst: 0,
        shipping_value: shippingCharges,
        shipping_gst: 0,
        sgst: 0,
        cgst: 0,
        igst: 0,
        total_gst: 0,
      };
      return newArr;
    }
  });
  return calculateGst;
};

export const getXBGstDataForAllOrders = (tableData) => {
  console.log("tabel data", tableData);
  const calculateGst = tableData.map((row) => {
    let clothTemp = [];
    let clothTempFor12 = [];
    let clothTempFor5 = [];
    let clothQuantityFor12 = [];
    let clothQuantityFor5 = [];
    let clothValueFor12 = [];
    let clothValueFor5 = [];
    let footwearTemp = [];
    let footwearQuantity = [];
    let footwearValue = [];

    row.order_items?.forEach((item) => {
      const st = item.sku;
      const regex = new RegExp("([0-9]+)|([a-zA-Z]+)", "g");
      const splittedSKU = st.match(regex)[0];
      // console.log("splitted sku", splittedSKU);
      const x = skuTypes.filter((x) => x.value === splittedSKU);
      if (x[0]?.label === "Clothing") {
        const finalSellingPrice =
          parseFloat(item.selling_price) - parseFloat(item.discount);
        // if combo split value in two
        //if value above 999 12 % else 5%
        if (
          (comboSKUs.includes(st.split(".")[0]) &&
            finalSellingPrice / 2 > 999) ||
          (!comboSKUs.includes(st.split(".")[0]) && finalSellingPrice > 999)
        ) {
          clothValueFor12.push(
            (parseFloat(item.selling_price) - parseFloat(item.discount)) *
              item.units
          );
          clothQuantityFor12.push(item.units);
          clothTempFor12.push(
            (parseFloat(item.selling_price) - parseFloat(item.discount)) *
              item.units -
              ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units) /
                1.12
          );
          clothTemp.push(
            (parseFloat(item.selling_price) - parseFloat(item.discount)) *
              item.units -
              ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units) /
                1.12
          );
        } else {
          clothValueFor5.push(
            (parseFloat(item.selling_price) - parseFloat(item.discount)) *
              item.units
          );
          clothQuantityFor5.push(item.units);
          clothTempFor5.push(
            (parseFloat(item.selling_price) - parseFloat(item.discount)) *
              item.units -
              ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units) /
                1.05
          );
          clothTemp.push(
            (parseFloat(item.selling_price) - parseFloat(item.discount)) *
              item.units -
              ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
                item.units) /
                1.05
          );
        }
      } else if (x[0]?.label === "Footwear") {
        footwearValue.push(
          (parseFloat(item.selling_price) - parseFloat(item.discount)) *
            item.units
        );
        footwearQuantity.push(item.units);
        footwearTemp.push(
          (parseFloat(item.selling_price) - parseFloat(item.discount)) *
            item.units -
            ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
              item.units) /
              1.12
        );
      } else {
        console.log("cannot find sku", splittedSKU);
      }
    });

    const totalFootwearGst = formatCurrency(
      footwearTemp.reduce((i, sum) => i + sum, 0)
    );
    const totalFootwearValue = formatCurrency(
      footwearValue.reduce((i, sum) => i + sum, 0)
    );
    const totalFootwearQuantity = footwearQuantity.reduce(
      (i, sum) => i + sum,
      0
    );
    const totalClothGst = formatCurrency(
      clothTemp.reduce((i, sum) => i + sum, 0)
    );
    const totalClothGstFor12 = formatCurrency(
      clothTempFor12.reduce((i, sum) => i + sum, 0)
    );
    const totalClothGstFor5 = formatCurrency(
      clothTempFor5.reduce((i, sum) => i + sum, 0)
    );
    const totalClothValueFor12 = formatCurrency(
      clothValueFor12.reduce((i, sum) => i + sum, 0)
    );
    const totalClothValueFor5 = formatCurrency(
      clothValueFor5.reduce((i, sum) => i + sum, 0)
    );
    const totalClothQuantityFor12 = clothQuantityFor12.reduce(
      (i, sum) => i + sum,
      0
    );
    const totalClothQuantityFor5 = clothQuantityFor5.reduce(
      (i, sum) => i + sum,
      0
    );

    const shippingCharges = row.shipping_charges;

    const shippingGST = row.shipping_charges - row.shipping_charges / 1.18;

    const shipmentGst = isNaN(formatCurrency(shippingGST))
      ? 0
      : formatCurrency(shippingGST);

    // if(totalClothValue)

    let sgst = 0;
    let cgst = 0;
    let igst = 0;

    const customer_address = row.billing_state;
    const totalGst = totalClothGst + totalFootwearGst;
    if (customer_address === "Delhi") {
      sgst = formatCurrency(totalGst / 2);
      cgst = formatCurrency(totalGst / 2);
      igst = 0;
    } else {
      sgst = 0;
      cgst = 0;
      igst = formatCurrency(totalGst);
    }
    // const clothGst = totalClothGst
    // const footwearGst = totalFootwearGst
    const gst = formatCurrency(totalGst + shipmentGst);

    const newArr = {
      order_number: row.fulfillment_id,
      replacementOrder: row.replacementOrder,
      order_date: row.order_date,
      channel: "Online store",
      status: row.shipping_status,
      inscanDate: row.inscanDate,
      delivery_date: row.delivered_date, //from excel
      rto_date: row.rto_date,
      customer: row.customer_name, //from excel
      "region&state": row.location,
      shipping_address: row.billing_state,
      awb: row.awb, //from excel
      "payment method": row.payment,
      // courier : row.shipments[0].courier,
      payment: row.total,
      "clothing value(12%)": totalClothValueFor12,
      "clothing quantity(12%)": totalClothQuantityFor12,
      "clothing value(5%)": totalClothValueFor5,
      "clothing quantity(5%)": totalClothQuantityFor5,
      "clothing gst(12%)": totalClothGstFor12,
      "clothing gst(5%)": totalClothGstFor5,
      clothing_gst: totalClothGst,
      footwear_value: totalFootwearValue,
      footwear_quantity: totalFootwearQuantity,
      footwear_gst: totalFootwearGst,
      shipping_value: shippingCharges,
      shipping_gst: shipmentGst,
      sgst,
      cgst,
      igst,
      total_gst:
        row.status === "Delivered" ||
        row.shipping_status === "Shipped" ||
        row.status === "DELIVERED" ||
        row.status === "NEW" ||
        row.status === "UNDELIVERED" ||
        row.status === "UNDELIVERED-3RD ATTEMPT" ||
        row.status === "CANCELLATION REQUESTED" ||
        row.status === "UNDELIVERED-2ND ATTEMPT" ||
        row.status === "MISROUTED" ||
        row.status === "UNDELIVERED-1ST ATTEMPT" ||
        row.status === "REACHED AT DESTINATION HUB" ||
        row.status === "IN TRANSIT" ||
        row.status === "OUT FOR DELIVERY" ||
        row.status === "PICKUP EXCEPTION" ||
        row.status === "READY TO SHIP" ||
        row.status === "DELIVERY DELAYED" ||
        row.status === "PICKUP SCHEDULED" ||
        row.status === "PICKUP RESCHEDULED" ||
        row.status === "In Transit"
          ? gst
          : -gst,
    };
    return newArr;
  });
  return calculateGst;
};

export const getGSTbyLineItem = (item) => {
  let gst = 0;
  const st = item.sku;
  const regex = new RegExp("([0-9]+)|([a-zA-Z]+)", "g");
  const splittedSKU = st.match(regex)[0];

  const x = skuTypes.filter((x) => x.value === splittedSKU);
  if (x[0]?.label === "Clothing") {
    const finalSellingPrice =
      parseFloat(item.selling_price) - parseFloat(item.discount);
    // if combo split value in two
    //if value above 999 12 % else 5%
    if (
      (comboSKUs.includes(st.split(".")[0]) && finalSellingPrice / 2 > 999) ||
      (!comboSKUs.includes(st.split(".")[0]) && finalSellingPrice > 999)
    ) {
      gst =
        (parseFloat(item.selling_price) - parseFloat(item.discount)) *
          item.units -
        ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
          item.units) /
          1.12;
    } else {
      gst =
        (parseFloat(item.selling_price) - parseFloat(item.discount)) *
          item.units -
        ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
          item.units) /
          1.05;
    }
  } else if (x[0]?.label === "Footwear") {
    gst =
      (parseFloat(item.selling_price) - parseFloat(item.discount)) *
        item.units -
      ((parseFloat(item.selling_price) - parseFloat(item.discount)) *
        item.units) /
        1.12;
  } else {
    console.log(`Cannot find ${splittedSKU}`);
  }

  return gst;
};
