import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { indianStates } from "../../utils/helperArrays";

const OrderNotTakeoutTable = ({ includeStates, states, orders }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (includeStates) {
      const filtered = orders.filter(
        (o) =>
          !indianStates.includes(o.billing_state) &&
          !indianStates.includes(o.shipping_state)
      );
      setData(filtered);
    }
  }, [orders, states, includeStates]);
  return (
    <div>
      {includeStates && data.length > 0 && (
        <div>
          <h3>Order that doesn't include the above States :</h3>
          {data.map((d) => (
            <h3>{d.fulfillment_id}</h3>
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderNotTakeoutTable;
