import { formatCurrency } from "./orderTakeoutHelpers";
import { getGSTbyLineItem } from "./gstHelpers";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { inWords } from "./functions";
import { getSizesByType, skuTypes, skuWiseTypes } from "./helperArrays";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const createPDFforManifest = (data) => {
    const allImages = data.reduce(
        (acc, item) => {
            const products = item.order_items
                .map((p) => ({ sku: p.sku, url: p.url }))
                .reduce(
                    (a, i) => ({
                        ...a,
                        [i.sku]: i.url,
                    }),
                    {}
                );
            return {
                ...acc,
                ...products,
            };
        },
        {
            DEFAULT:
                "https://cdn.shopify.com/s/files/1/0604/6957/5837/files/Littlebox_white_border_2_aae20d45-94e3-4b3f-adb1-674d40d94e9e_195x@2x.png?v=1649095358",
        }
    );

    const orderItems = data.reduce((acc, d) => {
        acc.push(...d.order_items);
        return acc;
    }, []);

    const skuWise = orderItems.reduce((acc, oi) => {
        const amt = parseInt(oi.units);
        const sku = oi.sku.split(".")[0];
        const alphabetPart = sku.match(/[A-Za-z]+/)[0];
        const size = oi.variant_title;
        const findType = skuWiseTypes.find((st) => st.sku === alphabetPart);
        if (!findType) {
            console.log("not found", sku);
            return acc;
        }
        const type = findType.type;
        const category = findType.category;
        const sizes = getSizesByType(type).reduce((acc, sizeRow) => {
            acc[sizeRow] = 0;
            return acc;
        }, {});
        const found = acc.find((ac) => ac.sku === sku);
        if (found) {
            found.quantity[size] = found.quantity[size] + amt;
            found.total = found.total + amt;
        } else {
            const newObj = {
                sku,
                url: oi.url,
                quantity: sizes,
                total: amt,
                type,
                category,
            };
            newObj.quantity[size] = newObj.quantity[size] + amt;
            acc.push(newObj);
        }
        return acc;
    }, []);
    const skuOnlyImages = skuWise.reduce(
        (acc, item) => {
            return {
                ...acc,
                [`${item.sku}`]: item.url,
            };
        },
        {
            DEFAULT:
                "https://cdn.shopify.com/s/files/1/0604/6957/5837/files/Littlebox_white_border_2_aae20d45-94e3-4b3f-adb1-674d40d94e9e_195x@2x.png?v=1649095358",
        }
    );
    const totalQuantity = skuWise.reduce((acc, s) => acc + s.total, 0);

    // category wise sorted
    const categoryWise = skuWise.reduce((acc, sk) => {
        const found = acc.find((ac) => ac.category === sk.category);
        if (found) {
            found.data = [...found.data, sk];
        } else {
            const newObj = {
                category: sk.category,
                data: [sk],
            };
            acc.push(newObj);
        }
        return acc;
    }, []);

    const createQuantityList = (quantity) => {
        const sizes = Object.keys(quantity);
        const temp = sizes.map((x) => {
            return [quantity[x]];
        });
        return [sizes, temp];
    };
    const categoryWiseProductTable = (data) => {
        const row = data.map((o) => [
            {
                image: o.sku || "DEFAULT",
                width: 40,
                height: 40,
            },
            `${o.sku}`,
            {
                style: "tableExample",
                table: {
                    // headerRows: 1,
                    body: createQuantityList(o.quantity),
                },
                layout: "noBorders",
            },
            o.total,
        ]);
        row.unshift([
            { text: "Image", style: "tableHeader" },
            { text: "SKU", style: "tableHeader" },
            { text: "Quantity", style: "tableHeader" },
            { text: "Total", style: "tableHeader" },
        ]);
        return row;
    };

    const docTable = categoryWise.reduce((acc, cw) => {
        const totalCategoryQnty = cw.data.reduce(
            (acc, item) => acc + item.total,
            0
        );
        const categoryName = { text: `${cw.category}`, style: "header" };
        const categoryInfo = `Total SKU's :${cw.data.length} Total Quantity : ${totalCategoryQnty}`;
        const data = cw.data;
        acc.push(categoryName, categoryInfo, {
            style: "tableExample",
            table: {
                headerRows: 1,
                body: categoryWiseProductTable(data),
            },
            // layout: "noBorders",
        });
        return acc;
    }, []);

    const skuInfoTable = categoryWise.reduce((acc, cw) => {
        const category = cw.category;
        const totalSKU = cw.data.length;
        const totalQnty = cw.data.reduce((accQnty, d) => accQnty + d.total, 0);
        const arr = [category, `${totalSKU}`, `${totalQnty}`];
        acc.push(arr);
        return acc;
    }, []);
    skuInfoTable.unshift([
        { text: "Category", style: "tableHeader" },
        { text: "Total SKU's", style: "tableHeader" },
        { text: "Total Quantity", style: "tableHeader" },
    ]);
    const skuInfoTableDef = {
        style: "tableExample",
        table: {
            headerRows: 1,
            body: skuInfoTable,
        },
        // layout: "noBorders",
    };

    const createImageList = (products) => {
        const temp = products.map((x) => {
            return [
                {
                    image: x.sku || "DEFAULT",
                    width: 80,
                    height: 80,
                },
            ];
        });
        return temp;
    };
    const createItemList = (products) => {
        const temp = products.map((x) => {
            return [
                {
                    text: [
                        x.sku.split(".")[0] + " -- ",
                        { text: x.variant_title, bold: true },
                    ],
                    margin: [0, 0, 0, 67],
                },
            ];
        });
        return temp;
    };
    const createUnitList = (products) => {
        const temp = products.map((x) => {
            return [
                {
                    text: x.units,
                    margin: [0, 0, 0, 67],
                },
            ];
        });
        return temp;
    };
    const productTable = data.map((o) => [
        {
            stack: [
                { text: o.original_order_id },
                { text: `(${o.fulfillment_id})` },
            ],
            margin: [0, 5, 0, 5],
        },
        {
            style: "tableExample",
            table: {
                // headerRows: 1,
                body: createItemList(o.order_items),
            },
            layout: "noBorders",
        },
        {
            style: "tableExample",
            table: {
                // headerRows: 1,
                body: createUnitList(o.order_items),
            },
            layout: "noBorders",
        },
        {
            style: "tableExample",
            table: {
                // headerRows: 1,
                body: createImageList(o.order_items),
            },
            layout: "noBorders",
        },
        { text: o.total, margin: [5, 5, 7, 5] },
    ]);

    productTable.unshift([
        { text: "Order No.", style: "tableHeader" },
        { text: "Item", style: "tableHeader" },
        { text: "Unit", style: "tableHeader" },
        { text: "Image", style: "tableHeader" },
        { text: "Price", style: "tableHeader" },
    ]);

    const docDefinition = {
        content: [
            { text: "Products Manifest", style: "header" },
            `Manifest Sheet For : Little Box India`,
            `Date : ${new Date().toLocaleDateString()}`,
            `Pickup Address : Santana Folks LLP`,
            `Total Quantity : ${totalQuantity}`,
            skuInfoTableDef,
            ...docTable,
            { text: "Order List", style: "header" },
            {
                style: "tableExample",
                table: {
                    headerRows: 1,
                    body: productTable,
                },
                // layout: "noBorders",
            },
        ],
        images: { ...allImages, ...skuOnlyImages },
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
            },
            subheader: {
                fontSize: 16,
                bold: false,
                margin: [0, 10, 0, 5],
            },
            tableExample: {
                margin: [0, 5, 0, 5],
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: "black",
            },
        },
    };

    // const options = {};
    console.log("here");

    const newFileName = new Date().toLocaleDateString(); //cryptoRandomString({ length: 20, type: "url-safe" });

    pdfMake.createPdf(docDefinition).download(newFileName);

    console.log(newFileName);

    return newFileName;
};

export const createPDFforAllOrders = (data, imageData) => {
    const allImages = imageData.reduce(
        (acc, item) => {
            return {
                ...acc,
                ...item,
            };
        },
        {
            DEFAULT:
                "https://cdn.shopify.com/s/files/1/0604/6957/5837/files/Littlebox_white_border_2_aae20d45-94e3-4b3f-adb1-674d40d94e9e_195x@2x.png?v=1649095358",
        }
    );

    const createCustomerDetails = (data) => {
        const newAddress = [data.name, data.phone, data.address1, data.zip];
        return newAddress;
    };

    const itemTable = (products) => {
        return products.map((x) => {
            return [
                {
                    image: x.sku || "DEFAULT",
                    width: 80,
                    height: 80,
                },

                x.sku +
                    " - " +
                    x.variant_title +
                    " - " +
                    x.title +
                    " - " +
                    x.quantity,
            ];
        });
    };

    const productTable = data.map((p) => [
        p.order,
        p.date,
        p.status || "pending",
        {
            style: "tableExample",
            table: {
                // headerRows: 1,
                body: itemTable(p.products),
            },
            layout: "noBorders",
        },
        p.products.length,
        createCustomerDetails(p.address),
        p.total,
    ]);

    productTable.unshift([
        { text: "Order No.", style: "tableHeader" },
        { text: "Order Date", style: "tableHeader" },
        { text: "Order Status", style: "tableHeader" },
        { text: "Items", style: "tableHeader" },

        { text: "Item Count", style: "tableHeader" },
        { text: "Customer Details", style: "tableHeader" },
        { text: "Price", style: "tableHeader" },
    ]);

    const docDefinition = {
        content: [
            { text: "Products Manifest", style: "header" },
            `Manifest Sheet For : Little Box India`,
            `Date : ${new Date().toLocaleDateString()}`,
            `Courier : Delhivery`,
            `Pickup Address : DELHI`,
            {
                style: "tableExample",
                table: {
                    headerRows: 1,
                    body: productTable,
                },
            },
        ],
        images: allImages,
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
            },
            subheader: {
                fontSize: 16,
                bold: false,
                margin: [0, 10, 0, 5],
            },
            tableExample: {
                margin: [0, 5, 0, 15],
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: "black",
            },
        },
    };

    // const options = {};

    const newFileName = new Date().toLocaleDateString(); //cryptoRandomString({ length: 20, type: "url-safe" });

    pdfMake.createPdf(docDefinition).download(newFileName);

    return newFileName;
};

export const createPDFForUnfulfilledOrders = (data) => {
    //Image Definitions
    const allImages = data.reduce(
        (acc, item) => {
            const products = { [item.sku]: item.src };
            return {
                ...acc,
                ...products,
            };
        },
        {
            default:
                "https://cdn.shopify.com/s/files/1/0604/6957/5837/files/Littlebox_white_border_2_aae20d45-94e3-4b3f-adb1-674d40d94e9e_195x@2x.png?v=1649095358",
        }
    );

    console.log(allImages);

    //Nested Table Definitions

    const nestedTable = (item) => {
        const sizes = getSizesByType(item.type); //sizes array
        const Numbers = ["Numbers", ...sizes, "Total"];
        const getShopifyStock = (size) => {
            const found = item.stock[0].filter((i) => i.size === size);
            if (found.length > 0) {
                return found[0].stock;
            } else {
                return 0;
            }
        };

        const sizeTotal = Object.values(item.sizes).reduce((acc, item) => {
            acc = acc + item;
            return acc;
        }, 0);

        const inventoryStockTotal = Object.values(item.inventoryStock).reduce(
            (acc, item) => {
                acc = acc + item;
                return acc;
            },
            0
        );

        const shopifyInventoryTotal = item.stock[0].reduce((acc, item) => {
            acc = acc + item.stock;
            return acc;
        }, 0);

        const OrderSizeCount = [
            "Order size count",
            ...sizes.map((s) => item.sizes[s] || 0),
            sizeTotal,
        ];
        const inventoryStock = [
            "Inventory Stock",
            ...sizes.map((s) => item.inventoryStock[s] || 0),
            inventoryStockTotal,
        ];
        const shopifyInventory = [
            "Shopify Inventory",
            ...sizes.map((s) => getShopifyStock(s)),
            shopifyInventoryTotal,
        ];

        const newItem = [
            Numbers,
            OrderSizeCount,
            inventoryStock,
            shopifyInventory,
        ];
        return newItem;
    };

    //Main Table Body

    const mainTable = data.map((item) => {
        const newBody = [
            {
                image: item.sku,
                fit: [100, 100],
            },
            item.sku,
            item.name,
            {
                style: "nestedTable",
                table: {
                    //----------Nested Table-------//
                    headerRows: 1,
                    body: nestedTable(item),
                },
                layout: "noBorders",
            },
        ];
        return newBody;
    });

    const pdfDefinition = {
        content: [
            { text: "Unfulfilled Orders", style: "header" },

            {
                style: "mainTable",
                table: {
                    headerRows: 1,

                    widths: ["auto", "auto", "auto", "auto"],
                    body: [["Image", "SKU", "Name", "Numbers"], ...mainTable],
                    layout: "auto",
                },
            },
        ],

        images: allImages,

        styles: {
            header: {
                fontSize: 22,
                bold: true,
            },
            anotherStyle: {
                fontSize: 15,
                italics: true,
            },
            nestedTable: {
                fontSize: 9,
            },
            mainTable: {
                fontSize: 10,
            },
        },
    };

    // Image definitions
    // const allImages = data.reduce(
    //   (acc, item) => {
    //     const products = { [item.sku]: item.src };
    //     return {
    //       ...acc,
    //       ...products,
    //     };
    //   },
    //   {
    //     DEFAULT:
    //       "https://cdn.shopify.com/s/files/1/0604/6957/5837/files/Littlebox_white_border_2_aae20d45-94e3-4b3f-adb1-674d40d94e9e_195x@2x.png?v=1649095358",
    //   }
    // );
    // console.log("all imahes", allImages);

    // const docDefinition = {
    //   content: [
    //     { text: "Products Manifest", style: "header" },
    //     `Manifest Sheet For : Little Box India`,
    //     `Date : ${new Date().toLocaleDateString()}`,
    //     `Pickup Address : Santana Folks LLP`,
    //     {
    //       style: "tableExample",
    //       table: {
    //         headerRows: 1,
    //         body: productTable,
    //       },
    //       // layout: "noBorders",
    //     },
    //   ],
    //   images: allImages,
    //   styles: {
    //     header: {
    //       fontSize: 18,
    //       bold: true,
    //       margin: [0, 0, 0, 10],
    //     },
    //     subheader: {
    //       fontSize: 16,
    //       bold: false,
    //       margin: [0, 10, 0, 5],
    //     },
    //     tableExample: {
    //       margin: [0, 5, 0, 5],
    //     },
    //     tableHeader: {
    //       bold: true,
    //       fontSize: 13,
    //       color: "black",
    //     },
    //   },
    // };

    // const options = {};
    // console.log("here");

    const newFileName = new Date().toLocaleDateString();

    pdfMake.createPdf(pdfDefinition).download(newFileName);

    console.log(newFileName);

    return newFileName;
};

export const createPDFForSkuWiseOrderOut = (data) => {
    const orderItems = data.reduce((acc, d) => {
        acc.push(...d.order_items);
        return acc;
    }, []);
    // create sku wise array
    const skuWise = orderItems.reduce((acc, oi) => {
        const amt = parseInt(oi.units);
        const sku = oi.sku.split(".")[0];
        const alphabetPart = sku.match(/[A-Za-z]+/)[0];
        const size = oi.variant_title;
        const findType = skuWiseTypes.find((st) => st.sku === alphabetPart);
        if (!findType) {
            console.log("not found", sku);
            return acc;
        }
        const type = findType.type;
        const category = findType.category;
        const sizes = getSizesByType(type).reduce((acc, sizeRow) => {
            acc[sizeRow] = 0;
            return acc;
        }, {});
        const found = acc.find((ac) => ac.sku === sku);
        if (found) {
            found.quantity[size] = found.quantity[size] + amt;
            found.total = found.total + amt;
        } else {
            const newObj = {
                sku,
                url: oi.url,
                quantity: sizes,
                total: amt,
                type,
                category,
            };
            newObj.quantity[size] = newObj.quantity[size] + amt;
            acc.push(newObj);
        }
        return acc;
    }, []);
    const totalQuantity = skuWise.reduce((acc, s) => acc + s.total, 0);
    // create images array for pdf
    const allImages = skuWise.reduce(
        (acc, item) => {
            return {
                ...acc,
                [`${item.sku}`]: item.url,
            };
        },
        {
            DEFAULT:
                "https://cdn.shopify.com/s/files/1/0604/6957/5837/files/Littlebox_white_border_2_aae20d45-94e3-4b3f-adb1-674d40d94e9e_195x@2x.png?v=1649095358",
        }
    );

    // category wise sorted
    const categoryWise = skuWise.reduce((acc, sk) => {
        const found = acc.find((ac) => ac.category === sk.category);
        if (found) {
            found.data = [...found.data, sk];
        } else {
            const newObj = {
                category: sk.category,
                data: [sk],
            };
            acc.push(newObj);
        }
        return acc;
    }, []);

    const createQuantityList = (quantity) => {
        const sizes = Object.keys(quantity);
        const temp = sizes.map((x) => {
            return [quantity[x]];
        });
        return [sizes, temp];
    };
    const productTable = (data) => {
        const row = data.map((o) => [
            {
                image: o.sku || "DEFAULT",
                width: 40,
                height: 40,
            },
            `${o.sku}`,
            {
                style: "tableExample",
                table: {
                    // headerRows: 1,
                    body: createQuantityList(o.quantity),
                },
                layout: "noBorders",
            },
            o.total,
        ]);
        row.unshift([
            { text: "Image", style: "tableHeader" },
            { text: "SKU", style: "tableHeader" },
            { text: "Quantity", style: "tableHeader" },
            { text: "Total", style: "tableHeader" },
        ]);
        return row;
    };

    const docTable = categoryWise.reduce((acc, cw) => {
        const totalCategoryQnty = cw.data.reduce(
            (acc, item) => acc + item.total,
            0
        );
        const categoryName = { text: `${cw.category}`, style: "header" };
        const categoryInfo = `Total SKU's :${cw.data.length} Total Quantity : ${totalCategoryQnty}`;
        const data = cw.data;
        acc.push(categoryName, categoryInfo, {
            style: "tableExample",
            table: {
                headerRows: 1,
                body: productTable(data),
            },
            // layout: "noBorders",
        });
        return acc;
    }, []);

    const skuInfoTable = categoryWise.reduce((acc, cw) => {
        const category = cw.category;
        const totalSKU = cw.data.length;
        const totalQnty = cw.data.reduce((accQnty, d) => accQnty + d.total, 0);
        const arr = [category, `${totalSKU}`, `${totalQnty}`];
        acc.push(arr);
        return acc;
    }, []);
    skuInfoTable.unshift([
        { text: "Category", style: "tableHeader" },
        { text: "Total SKU's", style: "tableHeader" },
        { text: "Total Quantity", style: "tableHeader" },
    ]);
    const skuInfoTableDef = {
        style: "tableExample",
        table: {
            headerRows: 1,
            body: skuInfoTable,
        },
        // layout: "noBorders",
    };

    const docDefinition = {
        content: [
            { text: "SKU Wise List", style: "header" },
            `Manifest Sheet For : Little Box India`,
            `Date : ${new Date().toLocaleDateString()}`,
            `Pickup Address : Santana Folks LLP`,
            `Total Quantity : ${totalQuantity}`,
            skuInfoTableDef,
            ...docTable,
        ],
        images: allImages,
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
            },
            subheader: {
                fontSize: 16,
                bold: false,
                margin: [0, 10, 0, 5],
            },
            tableExample: {
                margin: [0, 5, 0, 5],
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: "black",
            },
        },
    };

    // const options = {};

    const newFileName = `sku_quantity_${new Date().toLocaleDateString()}`; //cryptoRandomString({ length: 20, type: "url-safe" });

    pdfMake.createPdf(docDefinition).download(newFileName);

    console.log(newFileName);

    return newFileName;
};

export const createMaterialChallan = (
    context,
    materials,
    vendorName,
    challanNumber
) => {
    const materialTable = materials.reduce((acc, item, currentIndex) => {
        const serialNo = String(currentIndex + 1);
        const material = `${item.fabricName} ${item.colorName}`;
        const amt = String(item.amt);
        const arr = [serialNo, material, amt];
        acc.push(arr);
        return acc;
    }, []);
    materialTable.unshift([
        { text: "No.", style: "tableHeader" },
        { text: "Material", style: "tableHeader" },
        { text: "Amount (In Roll)", style: "tableHeader" },
    ]);
    // Main PDF
    const docDefinition = {
        content: [
            { text: "Good Tribe Pvt. Lmt", fontSize: 30, bold: true },
            `Challan Number# ${challanNumber}`,
            `${context} : ${vendorName}`,
            `Challan Date : ${new Date().toLocaleDateString("en-in")}`,
            {
                style: "tableExample",
                table: {
                    headerRows: 1,
                    body: materialTable,
                },
            },
        ],
        images: [],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
            },
            subheader: {
                fontSize: 16,
                bold: false,
                margin: [0, 10, 0, 5],
            },
            tableExample: {
                width: "100%",
                margin: [0, 50, 0, 5],
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: "black",
            },
            columnStyle: {
                margin: [0, 40, 0, 0],
            },
        },
    };

    // const options = {};

    const newFileName = `challan_${vendorName}_${new Date().toLocaleDateString(
        "en-in"
    )}`;

    pdfMake.createPdf(docDefinition).download(newFileName);

    console.log(newFileName);

    return newFileName;
};

export const createPDFforManifestForShiprocket = (data, courier) => {
    const allImages = data.reduce(
        (acc, item) => {
            const products = item.order_items
                .map((p) => ({ sku: p.sku, url: p.url }))
                .reduce(
                    (a, i) => ({
                        ...a,
                        [i.sku]: i.url,
                    }),
                    {}
                );
            return {
                ...acc,
                ...products,
            };
        },
        {
            DEFAULT:
                "https://cdn.shopify.com/s/files/1/0604/6957/5837/files/Littlebox_white_border_2_aae20d45-94e3-4b3f-adb1-674d40d94e9e_195x@2x.png?v=1649095358",
        }
    );

    const orderItems = data.reduce((acc, d) => {
        acc.push(...d.order_items);
        return acc;
    }, []);

    const skuWise = orderItems.reduce((acc, oi) => {
        const amt = parseInt(oi.units);
        const sku = oi.sku.split(".")[0];
        const alphabetPart = sku.match(/[A-Za-z]+/)[0];
        const size = oi.variant_title;
        const findType = skuWiseTypes.find((st) => st.sku === alphabetPart);
        if (!findType) {
            console.log("not found", sku);
            return acc;
        }
        const type = findType.type;
        const category = findType.category;
        const sizes = getSizesByType(type).reduce((acc, sizeRow) => {
            acc[sizeRow] = 0;
            return acc;
        }, {});
        const found = acc.find((ac) => ac.sku === sku);
        if (found) {
            found.quantity[size] = found.quantity[size] + amt;
            found.total = found.total + amt;
        } else {
            const newObj = {
                sku,
                url: oi.url,
                quantity: sizes,
                total: amt,
                type,
                category,
            };
            newObj.quantity[size] = newObj.quantity[size] + amt;
            acc.push(newObj);
        }
        return acc;
    }, []);
    const skuOnlyImages = skuWise.reduce(
        (acc, item) => {
            return {
                ...acc,
                [`${item.sku}`]: item.url,
            };
        },
        {
            DEFAULT:
                "https://cdn.shopify.com/s/files/1/0604/6957/5837/files/Littlebox_white_border_2_aae20d45-94e3-4b3f-adb1-674d40d94e9e_195x@2x.png?v=1649095358",
        }
    );
    const totalQuantity = skuWise.reduce((acc, s) => acc + s.total, 0);

    // category wise sorted
    const categoryWise = skuWise.reduce((acc, sk) => {
        const found = acc.find((ac) => ac.category === sk.category);
        if (found) {
            found.data = [...found.data, sk];
        } else {
            const newObj = {
                category: sk.category,
                data: [sk],
            };
            acc.push(newObj);
        }
        return acc;
    }, []);

    const createQuantityList = (quantity) => {
        const sizes = Object.keys(quantity);
        const temp = sizes.map((x) => {
            return [quantity[x]];
        });
        return [sizes, temp];
    };
    const categoryWiseProductTable = (data) => {
        const row = data.map((o) => [
            {
                image: o.sku || "DEFAULT",
                width: 40,
                height: 40,
            },
            `${o.sku}`,
            {
                style: "tableExample",
                table: {
                    // headerRows: 1,
                    body: createQuantityList(o.quantity),
                },
                layout: "noBorders",
            },
            o.total,
        ]);
        row.unshift([
            { text: "Image", style: "tableHeader" },
            { text: "SKU", style: "tableHeader" },
            { text: "Quantity", style: "tableHeader" },
            { text: "Total", style: "tableHeader" },
        ]);
        return row;
    };

    const docTable = categoryWise.reduce((acc, cw) => {
        const totalCategoryQnty = cw.data.reduce(
            (acc, item) => acc + item.total,
            0
        );
        const categoryName = { text: `${cw.category}`, style: "header" };
        const categoryInfo = `Total SKU's :${cw.data.length} Total Quantity : ${totalCategoryQnty}`;
        const data = cw.data;
        acc.push(categoryName, categoryInfo, {
            style: "tableExample",
            table: {
                headerRows: 1,
                body: categoryWiseProductTable(data),
            },
            // layout: "noBorders",
        });
        return acc;
    }, []);

    const skuInfoTable = categoryWise.reduce((acc, cw) => {
        const category = cw.category;
        const totalSKU = cw.data.length;
        const totalQnty = cw.data.reduce((accQnty, d) => accQnty + d.total, 0);
        const arr = [category, `${totalSKU}`, `${totalQnty}`];
        acc.push(arr);
        return acc;
    }, []);
    skuInfoTable.unshift([
        { text: "Category", style: "tableHeader" },
        { text: "Total SKU's", style: "tableHeader" },
        { text: "Total Quantity", style: "tableHeader" },
    ]);
    const skuInfoTableDef = {
        style: "tableExample",
        table: {
            headerRows: 1,
            body: skuInfoTable,
        },
        // layout: "noBorders",
    };

    const createImageList = (products) => {
        const temp = products.map((x) => {
            return [
                {
                    image: x.sku || "DEFAULT",
                    width: 80,
                    height: 80,
                },
            ];
        });
        return temp;
    };
    const createItemList = (products) => {
        const temp = products.map((x) => {
            return [
                {
                    text: [
                        x.sku.split(".")[0] + " -- ",
                        { text: x.variant_title, bold: true },
                    ],
                    margin: [0, 0, 0, 67],
                },
            ];
        });
        return temp;
    };
    const createUnitList = (products) => {
        const temp = products.map((x) => {
            return [
                {
                    text: x.units,
                    margin: [0, 0, 0, 67],
                },
            ];
        });
        return temp;
    };
    const productTable = data.map((o) => [
        {
            stack: [
                { text: o.original_order_id },
                { text: `(${o.fulfillment_id})` },
            ],
            margin: [0, 5, 0, 5],
        },
        {
            style: "tableExample",
            table: {
                // headerRows: 1,
                body: createItemList(o.order_items),
            },
            layout: "noBorders",
        },
        {
            style: "tableExample",
            table: {
                // headerRows: 1,
                body: createUnitList(o.order_items),
            },
            layout: "noBorders",
        },
        {
            style: "tableExample",
            table: {
                // headerRows: 1,
                body: createImageList(o.order_items),
            },
            layout: "noBorders",
        },
        { text: o.total, margin: [5, 5, 7, 5] },
    ]);

    productTable.unshift([
        { text: "Order No.", style: "tableHeader" },
        { text: "Item", style: "tableHeader" },
        { text: "Unit", style: "tableHeader" },
        { text: "Image", style: "tableHeader" },
        { text: "Price", style: "tableHeader" },
    ]);

    const docDefinition = {
        content: [
            { text: `Products Manifest (${courier})`, style: "header" },
            `Manifest Sheet For : Little Box India`,
            `Date : ${new Date().toLocaleDateString()}`,
            `Pickup Address : Santana Folks LLP`,
            `Total Quantity : ${totalQuantity}`,
            skuInfoTableDef,
            ...docTable,
            { text: "Order List", style: "header" },
            {
                style: "tableExample",
                table: {
                    headerRows: 1,
                    body: productTable,
                },
                // layout: "noBorders",
            },
        ],
        images: { ...allImages, ...skuOnlyImages },
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
            },
            subheader: {
                fontSize: 16,
                bold: false,
                margin: [0, 10, 0, 5],
            },
            tableExample: {
                margin: [0, 5, 0, 5],
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: "black",
            },
        },
    };

    // const options = {};
    console.log("here");
    const todayDate = new Date().toLocaleDateString();
    const newFileName = `${courier} ${todayDate}`; //cryptoRandomString({ length: 20, type: "url-safe" });

    pdfMake.createPdf(docDefinition).download(newFileName);

    console.log(newFileName);

    return newFileName;
};

export const createInvoicePDF = (order) => {
    const orderValue = order.total;
    const invoicing_date = order.processed_at;
    // order.pickedup_date
    //     ? order.pickedup_date
    //     : order.processed_at;

    // GST total order
    let totalGST = 0;

    const shipping = order.shipping_charges || "0.00";
    const shippingRate = shipping / 1.18;
    const shippingGst = shipping - shippingRate;
    totalGST += shippingGst;

    let count = 1;
    const allImages = {
        DEFAULT:
            "https://littleboxindia.com/cdn/shop/files/provicali_new_logo_2_1_260x.png?v=1661172939",
    };
    const totalQuantity = order.order_items.reduce(
        (sum, i) => i.units + sum,
        0
    );

    const productsTable = order.order_items.reduce((acc, item) => {
        const calculatedGST = getGSTbyLineItem(item);
        totalGST += calculatedGST;
        const name = item.name.substring(0, 80);
        const quantity = item.units;
        const discount = item.discount * quantity;
        const price = formatCurrency(
            item.selling_price * item.units - (discount + calculatedGST)
        ).toFixed(2);
        const gst = formatCurrency(calculatedGST).toFixed(2);
        const mrp = formatCurrency(item.selling_price * item.units).toFixed(2);
        const total = formatCurrency(
            item.selling_price * item.units - discount
        ).toFixed(2);
        const arr = [
            { text: count++, margin: [2, 2, 2, 2] },
            {
                text: name,
                bold: true,
                margin: [2, 2, 2, 2],
            },
            { text: "₹" + mrp, margin: [2, 2, 2, 2] },
            { text: quantity, margin: [2, 2, 2, 2] },
            {
                text: "₹" + formatCurrency(discount).toFixed(2),
                margin: [2, 2, 2, 2],
            },

            { text: "₹" + price, margin: [2, 2, 2, 2] },
            { text: "₹" + gst, margin: [2, 2, 2, 2] },
            {
                text: "₹" + total,
                margin: [2, 2, 2, 2],
            },
        ];
        acc.push(arr);
        return acc;
    }, []);

    productsTable.unshift([
        { text: " ", style: "tableHeader" },
        { text: "Product Name", style: "tableHeader" },
        { text: "M.R.P.", style: "tableHeader" },
        { text: "Quantity", style: "tableHeader" },
        { text: "Discounts", style: "tableHeader" },
        { text: "Price", style: "tableHeader" },
        { text: "GST", style: "tableHeader" },
        { text: "Total", style: "tableHeader" },
    ]);

    productsTable.push([
        { text: count, margin: [2, 2, 2, 2] },
        { text: "Shipping", margin: [2, 2, 2, 2] },
        { text: "", margin: [2, 2, 2, 2] },
        { text: "", margin: [2, 2, 2, 2] },
        { text: "", margin: [2, 2, 2, 2] },

        {
            text: "₹" + formatCurrency(shippingRate).toFixed(2),
            margin: [2, 2, 2, 2],
        },
        {
            text: "₹" + formatCurrency(shippingGst).toFixed(2),
            margin: [2, 2, 2, 2],
        },
        {
            text: "₹" + formatCurrency(shipping).toFixed(2),
            margin: [2, 2, 2, 2],
        },
    ]);
    // Main PDF
    const docDefinition = {
        content: [
            { text: "TAX INVOICE", style: "header" },
            {
                columns: [
                    {
                        image: "DEFAULT",
                        width: 150,
                    },
                    {
                        text: [
                            {
                                text: `Invoice # ${
                                    order.invoice_number
                                        ? order.invoice_number
                                        : order.awb_code
                                }`,
                            },
                            { text: `\nOrder # ${order.fulfillment_id}` },
                            {
                                text: `\nInvoice Date : ${new Date(
                                    invoicing_date
                                ).toLocaleDateString("en-IN", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                })}`,
                            },
                        ],
                        alignment: "right",
                    },
                ],
            },
            {
                columns: [
                    {
                        width: "50%",
                        table: {
                            headerRows: 0,
                            widths: ["*"],
                            body: [
                                [
                                    {
                                        fillColor: "#C2FAD8",
                                        margin: [10, 10],
                                        width: "100%",
                                        // Remove distasteful border
                                        border: [false, false, false, false],
                                        columns: [
                                            {
                                                text: [
                                                    {
                                                        text: "Billed By",
                                                        style: "colorheader",
                                                    },
                                                    {
                                                        text: "\nGood Tribe Pvt. Lmt",
                                                        bold: true,
                                                    },
                                                    {
                                                        text: `\nDelhi, India`,
                                                    },
                                                    {
                                                        text: `\nGSTIN: 07AAICG9642F1ZE`,
                                                        bold: true,
                                                    },
                                                    {
                                                        text: `\nPAN: AAICG9642F`,
                                                        bold: true,
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            ],
                        },
                    },
                    {
                        width: "50%",
                        table: {
                            headerRows: 0,
                            widths: ["*"],
                            body: [
                                [
                                    {
                                        fillColor: "#C2FAD8",
                                        width: "100%",
                                        margin: [10, 10],
                                        // Remove distasteful border
                                        border: [false, false, false, false],
                                        columns: [
                                            {
                                                text: [
                                                    {
                                                        text: "Billed To",
                                                        style: "colorheader",
                                                    },
                                                    {
                                                        text: `\n${
                                                            order?.shipping_customer_name +
                                                            " " +
                                                            order?.shipping_last_name
                                                        }`,
                                                    },
                                                    {
                                                        text: `\n${order?.shipping_city}, ${order?.shipping_state}, India ${order?.shipping_pincode}`,
                                                    },
                                                    {
                                                        text: `\nOrder Date : ${new Date(
                                                            order.order_date
                                                        ).toLocaleDateString(
                                                            "en-IN",
                                                            {
                                                                year: "numeric",
                                                                month: "short",
                                                                day: "numeric",
                                                            }
                                                        )}`,
                                                    },
                                                    {
                                                        text: `\nTotal Quantity : ${totalQuantity}`,
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            ],
                        },
                    },
                ],
                columnGap: 10,
                margin: [0, 20],
            },
            {
                style: "tableExample",
                table: {
                    headerRows: 1,
                    widths: [10, "*", 40, 40, 40, 40, 40, 40],
                    body: productsTable,
                },
                layout: {
                    hLineWidth: () => 1,
                    hLineColor: function (i, node) {
                        return i === 0 || i === node.table.body.length
                            ? "black"
                            : "#f0f0f0";
                    },
                    vLineWidth: () => 1,
                    vLineColor: function (i, node) {
                        return i === 0 || i === node.table.widths.length
                            ? "black"
                            : "#f0f0f0";
                    },
                    fillColor: (rowIndex, node, columnIndex) => {
                        return rowIndex === 0
                            ? "#785240"
                            : rowIndex % 2 === 0
                            ? "#f0f0f0"
                            : null;
                    },
                },
            },

            {
                columns: [
                    {
                        width: "60%",
                        table: {
                            headerRows: 0,
                            widths: ["*"],
                            body: [
                                [
                                    {
                                        fillColor: "#C2FAD8",
                                        width: "100%",
                                        margin: [10, 10],
                                        // Remove distasteful border
                                        border: [false, false, false, false],
                                        columns: [
                                            {
                                                text: [
                                                    {
                                                        text: "Shipping Details",
                                                        style: "colorheader",
                                                    },
                                                    {
                                                        text: `\nName : ${
                                                            order?.shipping_customer_name +
                                                            " " +
                                                            order?.shipping_last_name
                                                        }`,
                                                    },
                                                    {
                                                        text:
                                                            "\n" +
                                                            order?.shipping_address +
                                                            (order?.shipping_address2 ||
                                                                ""),
                                                    },
                                                    {
                                                        text: `\nCity : ${order?.shipping_city}`,
                                                    },
                                                    {
                                                        text: `\nState : ${order?.shipping_state}`,
                                                    },
                                                    {
                                                        text: `\nPIN : ${order?.shipping_pincode}`,
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            ],
                        },
                    },
                    {
                        width: "40%",
                        table: {
                            headerRows: 0,
                            widths: ["*"],
                            body: [
                                [
                                    {
                                        width: "100%",
                                        // Remove distasteful border
                                        border: [false, false, false, false],
                                        columns: [
                                            [
                                                {
                                                    columns: [
                                                        {
                                                            text: "Amount",
                                                        },
                                                        {
                                                            text:
                                                                "₹" +
                                                                (
                                                                    parseFloat(
                                                                        order.total
                                                                    ) - totalGST
                                                                ).toFixed(2),
                                                            alignment: "right",
                                                            margin: [
                                                                0, 0, 0, 10,
                                                            ],
                                                        },
                                                    ],
                                                },
                                                {
                                                    width: "100%",
                                                    // Remove distasteful border
                                                    border: [
                                                        false,
                                                        false,
                                                        false,
                                                        false,
                                                    ],
                                                    columns: [
                                                        {
                                                            text: "GST",
                                                        },
                                                        {
                                                            text:
                                                                "₹" +
                                                                formatCurrency(
                                                                    totalGST
                                                                ).toFixed(2),
                                                            alignment: "right",
                                                            margin: [
                                                                0, 0, 0, 10,
                                                            ],
                                                        },
                                                    ],
                                                },
                                                {
                                                    width: "100%",
                                                    // Remove distasteful border
                                                    border: [
                                                        false,
                                                        true,
                                                        false,
                                                        true,
                                                    ],
                                                    columns: [
                                                        {
                                                            text: "Total",
                                                        },
                                                        {
                                                            text:
                                                                "₹" +
                                                                formatCurrency(
                                                                    orderValue
                                                                ).toFixed(2),
                                                            bold: true,
                                                            alignment: "right",
                                                            margin: [
                                                                0, 0, 0, 10,
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        ],
                                    },
                                ],
                            ],
                        },
                    },
                ],
                columnGap: 10,
                margin: [0, 10],
            },
            {
                text: `Total (in words): ${inWords(
                    parseFloat(orderValue)
                ).toUpperCase()}`,
                style: "colorheader",
                margin: [0, 10, 0, 0],
            },
            { text: "\n\nTerms and Conditions:\n", style: "colorheader" },
            {
                ol: [
                    "Please review your invoice for accuracy. If you notice any discrepancies or errors, notify us within 7 days of receipt for correction.",
                    "For information on our exchange and return policy, please visit https://littleboxindia.com/pages/return-exchanges-policy. Returns/Exchanges will be processed according to the terms outlined in our policy.",
                    "Delivery times are estimates and not guaranteed. We are not responsible for delays caused by third-party carriers.",
                    "We respect your privacy. Your personal information will be handled in accordance with our Privacy Policy, which can be reviewed at https://littleboxindia.com/pages/privacy-policy",
                    "We reserve the right to modify these terms and conditions at any time. Changes will be effective immediately upon posting to our website or inclusion in future invoices.",
                ],
                fontSize: 8,
            },
        ],
        images: { ...allImages },
        styles: {
            header: {
                fontSize: 16,
                bold: true,
                color: "#785240",
                alignment: "center",
                margin: [0, 0, 0, 10],
            },
            colorheader: {
                fontSize: 12,
                color: "#785240",
                alignment: "left",
                margin: [0, 0, 0, 10],
            },
            subheader: {
                fontSize: 12,
                bold: false,
                margin: [0, 10, 0, 5],
            },
            tableExample: {
                fontSize: 8,
                margin: [0, 5, 0, 5],
            },
            tableHeader: {
                bold: true,
                fontSize: 8,
                color: "white",
            },
            card: {
                background: "#f0f0f0",
                fontSize: 10,
                color: "black",
            },
        },
    };

    const newFileName = `invoice_${order.awb_code}`;

    pdfMake.createPdf(docDefinition).download(newFileName);

    console.log(newFileName);

    return newFileName;
};

export const createInvoicePDFBulk = (orders) => {
    const dispatchDate = new Date(orders[0].processed_at).toLocaleDateString(
        "en-IN"
    );
    const pdfContent = [];
    const allImages = {
        DEFAULT:
            "https://littleboxindia.com/cdn/shop/files/provicali_new_logo_2_1_260x.png?v=1661172939",
    };
    // Loop on all orders
    for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        const orderValue = order.total;
        const invoicing_date = order.processed_at;
        // order.pickedup_date
        //     ? order.pickedup_date
        //     : order.processed_at;

        // GST total order
        let totalGST = 0;

        const shipping = order.shipping_charges || "0.00";
        const shippingRate = shipping / 1.18;
        const shippingGst = shipping - shippingRate;
        totalGST += shippingGst;

        let count = 1;

        const totalQuantity = order.order_items.reduce(
            (sum, i) => i.units + sum,
            0
        );

        const productsTable = order.order_items.reduce((acc, item) => {
            const calculatedGST = getGSTbyLineItem(item);
            totalGST += calculatedGST;
            const name = item.name.substring(0, 80);
            const quantity = item.units;
            const discount = item.discount * quantity;
            const price = formatCurrency(
                item.selling_price * item.units - (discount + calculatedGST)
            ).toFixed(2);
            const gst = formatCurrency(calculatedGST).toFixed(2);
            const mrp = formatCurrency(item.selling_price * item.units).toFixed(
                2
            );
            const total = formatCurrency(
                item.selling_price * item.units - discount
            ).toFixed(2);
            const arr = [
                { text: count++, margin: [2, 2, 2, 2] },
                {
                    text: name,
                    bold: true,
                    margin: [2, 2, 2, 2],
                },
                { text: "₹" + mrp, margin: [2, 2, 2, 2] },
                { text: quantity, margin: [2, 2, 2, 2] },
                {
                    text: "₹" + formatCurrency(discount).toFixed(2),
                    margin: [2, 2, 2, 2],
                },

                { text: "₹" + price, margin: [2, 2, 2, 2] },
                { text: "₹" + gst, margin: [2, 2, 2, 2] },
                {
                    text: "₹" + total,
                    margin: [2, 2, 2, 2],
                },
            ];
            acc.push(arr);
            return acc;
        }, []);

        productsTable.unshift([
            { text: " ", style: "tableHeader" },
            { text: "Product Name", style: "tableHeader" },
            { text: "M.R.P.", style: "tableHeader" },
            { text: "Quantity", style: "tableHeader" },
            { text: "Discounts", style: "tableHeader" },
            { text: "Price", style: "tableHeader" },
            { text: "GST", style: "tableHeader" },
            { text: "Total", style: "tableHeader" },
        ]);

        productsTable.push([
            { text: count, margin: [2, 2, 2, 2] },
            { text: "Shipping", margin: [2, 2, 2, 2] },
            { text: "", margin: [2, 2, 2, 2] },
            { text: "", margin: [2, 2, 2, 2] },
            { text: "", margin: [2, 2, 2, 2] },

            {
                text: "₹" + formatCurrency(shippingRate).toFixed(2),
                margin: [2, 2, 2, 2],
            },
            {
                text: "₹" + formatCurrency(shippingGst).toFixed(2),
                margin: [2, 2, 2, 2],
            },
            {
                text: "₹" + formatCurrency(shipping).toFixed(2),
                margin: [2, 2, 2, 2],
            },
        ]);

        const orderContent = [
            { text: "TAX INVOICE", style: "header" },
            {
                columns: [
                    {
                        image: "DEFAULT",
                        width: 150,
                    },
                    {
                        text: [
                            {
                                text: `Invoice # ${
                                    order.invoice_number
                                        ? order.invoice_number
                                        : order.awb_code
                                }`,
                            },
                            { text: `\nOrder # ${order.fulfillment_id}` },
                            {
                                text: `\nInvoice Date : ${new Date(
                                    invoicing_date
                                ).toLocaleDateString("en-IN", {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                })}`,
                            },
                        ],
                        alignment: "right",
                    },
                ],
            },
            {
                columns: [
                    {
                        width: "50%",
                        table: {
                            headerRows: 0,
                            widths: ["*"],
                            body: [
                                [
                                    {
                                        fillColor: "#C2FAD8",
                                        margin: [10, 10],
                                        width: "100%",
                                        // Remove distasteful border
                                        border: [false, false, false, false],
                                        columns: [
                                            {
                                                text: [
                                                    {
                                                        text: "Billed By",
                                                        style: "colorheader",
                                                    },
                                                    {
                                                        text: "\nGood Tribe Pvt. Lmt",
                                                        bold: true,
                                                    },
                                                    {
                                                        text: `\nDelhi, India`,
                                                    },
                                                    {
                                                        text: `\nGSTIN: 07AAICG9642F1ZE`,
                                                        bold: true,
                                                    },
                                                    {
                                                        text: `\nPAN: AAICG9642F`,
                                                        bold: true,
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            ],
                        },
                    },
                    {
                        width: "50%",
                        table: {
                            headerRows: 0,
                            widths: ["*"],
                            body: [
                                [
                                    {
                                        fillColor: "#C2FAD8",
                                        width: "100%",
                                        margin: [10, 10],
                                        // Remove distasteful border
                                        border: [false, false, false, false],
                                        columns: [
                                            {
                                                text: [
                                                    {
                                                        text: "Billed To",
                                                        style: "colorheader",
                                                    },
                                                    {
                                                        text: `\n${
                                                            order?.shipping_customer_name +
                                                            " " +
                                                            order?.shipping_last_name
                                                        }`,
                                                    },
                                                    {
                                                        text: `\n${order?.shipping_city}, ${order?.shipping_state}, India ${order?.shipping_pincode}`,
                                                    },
                                                    {
                                                        text: `\nOrder Date : ${new Date(
                                                            order.order_date
                                                        ).toLocaleDateString(
                                                            "en-IN",
                                                            {
                                                                year: "numeric",
                                                                month: "short",
                                                                day: "numeric",
                                                            }
                                                        )}`,
                                                    },
                                                    {
                                                        text: `\nTotal Quantity : ${totalQuantity}`,
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            ],
                        },
                    },
                ],
                columnGap: 10,
                margin: [0, 20],
            },
            {
                style: "tableExample",
                table: {
                    headerRows: 1,
                    widths: [10, "*", 40, 40, 40, 40, 40, 40],
                    body: productsTable,
                },
                layout: {
                    hLineWidth: () => 1,
                    hLineColor: function (i, node) {
                        return i === 0 || i === node.table.body.length
                            ? "black"
                            : "#f0f0f0";
                    },
                    vLineWidth: () => 1,
                    vLineColor: function (i, node) {
                        return i === 0 || i === node.table.widths.length
                            ? "black"
                            : "#f0f0f0";
                    },
                    fillColor: (rowIndex, node, columnIndex) => {
                        return rowIndex === 0
                            ? "#785240"
                            : rowIndex % 2 === 0
                            ? "#f0f0f0"
                            : null;
                    },
                },
            },

            {
                columns: [
                    {
                        width: "60%",
                        table: {
                            headerRows: 0,
                            widths: ["*"],
                            body: [
                                [
                                    {
                                        fillColor: "#C2FAD8",
                                        width: "100%",
                                        margin: [10, 10],
                                        // Remove distasteful border
                                        border: [false, false, false, false],
                                        columns: [
                                            {
                                                text: [
                                                    {
                                                        text: "Shipping Details",
                                                        style: "colorheader",
                                                    },
                                                    {
                                                        text: `\nName : ${
                                                            order?.shipping_customer_name +
                                                            " " +
                                                            order?.shipping_last_name
                                                        }`,
                                                    },
                                                    {
                                                        text:
                                                            "\n" +
                                                            order?.shipping_address +
                                                            (order?.shipping_address2 ||
                                                                ""),
                                                    },
                                                    {
                                                        text: `\nCity : ${order?.shipping_city}`,
                                                    },
                                                    {
                                                        text: `\nState : ${order?.shipping_state}`,
                                                    },
                                                    {
                                                        text: `\nPIN : ${order?.shipping_pincode}`,
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            ],
                        },
                    },
                    {
                        width: "40%",
                        table: {
                            headerRows: 0,
                            widths: ["*"],
                            body: [
                                [
                                    {
                                        width: "100%",
                                        // Remove distasteful border
                                        border: [false, false, false, false],
                                        columns: [
                                            [
                                                {
                                                    columns: [
                                                        {
                                                            text: "Amount",
                                                        },
                                                        {
                                                            text:
                                                                "₹" +
                                                                (
                                                                    parseFloat(
                                                                        order.total
                                                                    ) - totalGST
                                                                ).toFixed(2),
                                                            alignment: "right",
                                                            margin: [
                                                                0, 0, 0, 10,
                                                            ],
                                                        },
                                                    ],
                                                },
                                                {
                                                    width: "100%",
                                                    // Remove distasteful border
                                                    border: [
                                                        false,
                                                        false,
                                                        false,
                                                        false,
                                                    ],
                                                    columns: [
                                                        {
                                                            text: "GST",
                                                        },
                                                        {
                                                            text:
                                                                "₹" +
                                                                formatCurrency(
                                                                    totalGST
                                                                ).toFixed(2),
                                                            alignment: "right",
                                                            margin: [
                                                                0, 0, 0, 10,
                                                            ],
                                                        },
                                                    ],
                                                },
                                                {
                                                    width: "100%",
                                                    // Remove distasteful border
                                                    border: [
                                                        false,
                                                        true,
                                                        false,
                                                        true,
                                                    ],
                                                    columns: [
                                                        {
                                                            text: "Total",
                                                        },
                                                        {
                                                            text:
                                                                "₹" +
                                                                formatCurrency(
                                                                    orderValue
                                                                ).toFixed(2),
                                                            bold: true,
                                                            alignment: "right",
                                                            margin: [
                                                                0, 0, 0, 10,
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        ],
                                    },
                                ],
                            ],
                        },
                    },
                ],
                columnGap: 10,
                margin: [0, 10],
            },
            {
                text: `Total (in words): ${inWords(
                    parseFloat(orderValue)
                ).toUpperCase()}`,
                style: "colorheader",
                margin: [0, 10, 0, 0],
            },
            { text: "\n\nTerms and Conditions:\n", style: "colorheader" },
            {
                ol: [
                    "Please review your invoice for accuracy. If you notice any discrepancies or errors, notify us within 7 days of receipt for correction.",
                    "For information on our exchange and return policy, please visit https://littleboxindia.com/pages/return-exchanges-policy. Returns/Exchanges will be processed according to the terms outlined in our policy.",
                    "Delivery times are estimates and not guaranteed. We are not responsible for delays caused by third-party carriers.",
                    "We respect your privacy. Your personal information will be handled in accordance with our Privacy Policy, which can be reviewed at https://littleboxindia.com/pages/privacy-policy",
                    "We reserve the right to modify these terms and conditions at any time. Changes will be effective immediately upon posting to our website or inclusion in future invoices.",
                ],
                fontSize: 8,
                pageBreak: "after",
            },
        ];
        pdfContent.push(...orderContent);
    }
    // Main PDF
    const docDefinition = {
        content: pdfContent,
        images: { ...allImages },
        styles: {
            header: {
                fontSize: 16,
                bold: true,
                color: "#785240",
                alignment: "center",
                margin: [0, 0, 0, 10],
            },
            colorheader: {
                fontSize: 12,
                color: "#785240",
                alignment: "left",
                margin: [0, 0, 0, 10],
            },
            subheader: {
                fontSize: 12,
                bold: false,
                margin: [0, 10, 0, 5],
            },
            tableExample: {
                fontSize: 8,
                margin: [0, 5, 0, 5],
            },
            tableHeader: {
                bold: true,
                fontSize: 8,
                color: "white",
            },
            card: {
                background: "#f0f0f0",
                fontSize: 10,
                color: "black",
            },
        },
    };

    const newFileName = `invoices_${dispatchDate}`;

    pdfMake.createPdf(docDefinition).download(newFileName);

    console.log(newFileName);

    return newFileName;
};
