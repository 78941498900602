import React, { useEffect, useRef, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  cuttingVendors,
  finishingVendors,
  POVendors,
  stitchingVendors,
} from "../../../utils/helperArrays";
import VendorWiseQntyInput from "./VendorWiseQntyInput";

const vendorList = (stage) => {
  if (stage === "cutting") return cuttingVendors;
  else if (stage === "stitching") return stitchingVendors;
  else if (stage === "finishing") return finishingVendors;
  else if (stage === "PO") return POVendors;
  else return [];
};
const toSendVendorList = (stage) => {
  if (stage === "PO") return cuttingVendors;
  else if (stage === "cutting") return stitchingVendors;
  else if (stage === "stitching") return finishingVendors;
  else return [];
};
const ProcessedQnty = ({
  keyName,
  qnty,
  vendorQnty,
  handleClick,
  handleEdit,
  action,
  handleAddPo,
  sizes,
}) => {
  const [open, setOpen] = useState(false);
  // const [enteredQnty, setEnteredQnty] = useState({ ...totalQnty });
  // const [enteredQnty, setEnteredQnty] = useState({
  //   "001": {
  //     XS: 0,
  //     S: 0,
  //     M: 0,
  //     L: 0,
  //     XL: 0,
  //     FS: 0,
  //   },
  //   "002": {
  //     XS: 0,
  //     S: 0,
  //     M: 0,
  //     L: 0,
  //     XL: 0,
  //     FS: 0,
  //   },
  //   "003": {
  //     XS: 0,
  //     S: 0,
  //     M: 0,
  //     L: 0,
  //     XL: 0,
  //     FS: 0,
  //   },
  //   "004": {
  //     XS: 0,
  //     S: 0,
  //     M: 0,
  //     L: 0,
  //     XL: 0,
  //     FS: 0,
  //   },
  // });
  const [enteredQnty, setEnteredQnty] = useState(vendorQnty);
  const [totalQnty, setTotalQnty] = useState(qnty);
  const [selectedVendor, setSelectedVendor] = useState("");
  const todayDate = new Date().toISOString().split("T")[0];
  // 2024-11-07
  const [date, setDate] = useState(todayDate);

  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);
  const openMenu = Boolean(anchorEl);
  useEffect(() => {
    setEnteredQnty(vendorQnty);
  }, [vendorQnty]);
  useEffect(() => {
    setTotalQnty(qnty);
  }, [qnty]);
  const handleOpenMenu = (event, code) => {
    setAnchorEl(event.currentTarget);
    setSelectedVendor(code);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //vendor
  const vendors = vendorList(keyName);
  const toSendVendors = toSendVendorList(keyName);
  return (
    <Box my={4}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {String(keyName).toLocaleUpperCase()}
      </Typography>
      <Stack
        direction={"row"}
        gap={2}
        sx={{
          // justifyContent: "center",
          alignItems: "center",
        }}
      >
        {sizes.map((q, i) => (
          <Typography
            key={i}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {q} - {totalQnty[q]}
          </Typography>
        ))}
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Stack>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box>
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => handleEdit(keyName, enteredQnty)}
          >
            Edit
          </Button>
          {vendors.map((d, key) => (
            <Box my={2}>
              <h3>{d.name}</h3>
              <Stack direction={"row"} gap={2} key={key}>
                {sizes.map((q, i) => (
                  <VendorWiseQntyInput
                    key={i}
                    size={q}
                    vendorId={d.code}
                    enteredQnty={enteredQnty}
                    setEnteredQnty={setEnteredQnty}
                  />
                ))}

                <Stack
                  direction={"column"}
                  gap={1}
                  sx={{ justifyContent: "end" }}
                >
                  {keyName === "finishing" ? (
                    <Button
                      variant="outlined"
                      onClick={() =>
                        handleClick(enteredQnty[d.code], d.code, date)
                      }
                    >
                      {action}
                    </Button>
                  ) : (
                    <Stack direction={"row"} gap={2}>
                      {/* <TextField
                        sx={{ width: "50px" }}
                        type="date"
                        size="small"
                        variant="standard"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        label="date"
                      /> */}
                      <Button
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                        size="small"
                        ref={buttonRef}
                        id="basic-button"
                        aria-controls={openMenu ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? "true" : undefined}
                        onClick={(e) => handleOpenMenu(e, d.code)}
                        variant="outlined"
                      >
                        {action}
                      </Button>
                    </Stack>
                  )}

                  {keyName === "PO" && (
                    <Button
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={() => handleAddPo(enteredQnty[d.code], d.code)}
                    >
                      Add Qnty
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Box>
          ))}
        </Box>
      </Collapse>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {toSendVendors.map((sv) => (
          <MenuItem
            key={sv.code}
            onClick={() => {
              handleClose();
              handleClick(
                enteredQnty[selectedVendor],
                selectedVendor,
                sv.code,
                date
              );
            }}
          >
            {sv.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ProcessedQnty;
