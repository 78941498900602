import { MenuItem, Paper, Stack, TextField } from "@mui/material";
import React, { useEffect } from "react";

const Filter = ({
  orders,
  setTableData,
  statuses,
  comments,
  couriers,
  selectedStatus,
  setSelectedStatus,
  selectedComment,
  setSelectedComment,
  selectedCourier,
  setSelectedCourier,
  selectedProcessed,
  setSelectedProcessed,
  selectedPayment,
  setSelectedPayment,
}) => {
  useEffect(() => {
    if (orders.length > 0) {
      const filterStatus = orders.filter(
        (t) => t.package_status === selectedStatus || selectedStatus === "All"
      );
      const filterComment = filterStatus.filter(
        (t) => t.shiprocket_msg === selectedComment || selectedComment === "All"
      );
      const filterCourier = filterComment.filter(
        (t) =>
          t.courier_name === selectedCourier ||
          selectedCourier === "All" ||
          (selectedCourier === "Non Courier" && !t.courier_name) ||
          (selectedCourier === "Non Courier(Action)" &&
            !t.courier_name &&
            t.processed_at)
      );
      const filterProcessed = filterCourier.filter(
        (t) =>
          (t.isProcessed && selectedProcessed === "yes") ||
          (!t.isProcessed && selectedProcessed === "no") ||
          selectedProcessed === "All"
      );
      const filterPayment = filterProcessed.filter(
        (t) =>
          selectedPayment === "All" ||
          (t.payment === "paid" && selectedPayment === "paid") ||
          (t.payment !== "paid" && selectedPayment === "cod")
      );
      setTableData(filterPayment);
    }
  }, [
    orders,
    selectedStatus,
    selectedComment,
    selectedCourier,
    selectedProcessed,
    setTableData,
    selectedPayment,
  ]);
  return (
    <Stack
      my={1}
      component={Paper}
      direction={"row"}
      gap={2}
      py={2}
      px={3}
      sx={{ justifyContent: "center" }}
    >
      <TextField
        fullWidth
        label="package status"
        type="text"
        value={selectedStatus}
        onChange={(e) => setSelectedStatus(e.target.value)}
        size="small"
        select
      >
        {statuses.map((x, i) => (
          <MenuItem key={i} value={x}>
            {x}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        label="Courier"
        type="text"
        value={selectedCourier}
        onChange={(e) => setSelectedCourier(e.target.value)}
        size="small"
        select
      >
        {couriers.map((x, i) => (
          <MenuItem key={i} value={x}>
            {x}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        label="shiprocket message"
        type="text"
        value={selectedComment}
        onChange={(e) => setSelectedComment(e.target.value)}
        size="small"
        select
      >
        {comments.map((x, i) => (
          <MenuItem key={i} value={x}>
            {x}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        label="payment"
        type="text"
        value={selectedPayment}
        onChange={(e) => setSelectedPayment(e.target.value)}
        size="small"
        select
      >
        {["All", "paid", "cod"].map((x, i) => (
          <MenuItem key={i} value={x}>
            {x}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        label="processed"
        type="text"
        value={selectedProcessed}
        onChange={(e) => setSelectedProcessed(e.target.value)}
        size="small"
        select
      >
        {["All", "yes", "no"].map((x, i) => (
          <MenuItem key={i} value={x}>
            {x}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
};

export default Filter;
