import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Typography,
  Modal,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Checkbox,
  Button,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { showFullScreenLoader } from "../../actions/loaderActions";
import {
  replenishDamagedRTOorder,
  updateDamagedRtoOrders,
} from "../../actions/ordersActions";
import { updateReconProcessedAt } from "../../actions/reverseDeliveryActions";
import { toast } from "react-toastify";

export const RtoDamagedModal = ({
  open,
  setSelectDamagedModal,
  orderProducts,
  productVariants,
  orderId,
  setSearchValue,
  setSearchAwbValue,
}) => {
  const dispatch = useDispatch();
  const [openUp, setOpenUp] = useState(open);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [isDamagedMarked, setIsDamagedMarked] = useState(false);
  //   console.log(selectedOrders)
  console.log("Rendered");
  console.log("orderProducts", orderProducts);
  console.log("productVariants", productVariants);
  console.log("orderId", orderId);

  const handleClose = () => {
    setOpenUp(false);
    setSelectDamagedModal(false);
    setSelectedOrders([]);
  };

  const handleSubmit = async () => {
    // dispatch(showFullScreenLoader());
    const toBemarkedDamagedItems = selectedOrders.map((x) => ({
      ...x,
      damaged: true,
    }));
    console.log("toBemarkedDamagedItems", toBemarkedDamagedItems); // toBemarkedDamagedItems -> checked

    const toBeUpdatedInDynamo = orderProducts.filter(
      (x) => !selectedOrders.includes(x)
    );
    console.log("toBeUpdatedInDynamo", toBeUpdatedInDynamo); // toBeUpdatedInDynamo -> not checked

    // let updatedBodyShopify = []; // updated in shopify confirmation
    // const toBeUpdatedInShopify = toBeUpdatedInDynamo?.map((x) => {
    //   const filteredVariant = productVariants.filter(
    //     (item) =>
    //       item.sku === x.sku.split(".")[0] && item.title === x.variant_title
    //   );
    //   if (filteredVariant)
    //     updatedBodyShopify.push({
    //       location_id: 65807843485,
    //       available_adjustment: x.units,
    //       inventory_item_id: filteredVariant[0].inventory_item_id,
    //     });
    //   else return;
    // });

    // console.log("updatedBodyShopify", updatedBodyShopify);

    const toBeUpdatedBody = {
      id: orderId,
      order_items: [...toBeUpdatedInDynamo, ...toBemarkedDamagedItems],
      toBeUpdatedItems: toBeUpdatedInDynamo,
    };
    console.log("toBeUpdatedBody", toBeUpdatedBody);
    const reconItems = [
      ...toBemarkedDamagedItems.map((o) => ({
        sku: o.sku,
        units: o.units,
        variant_title: o.variant_title,
        damaged: true,
      })),
      ...toBeUpdatedInDynamo.map((o) => ({
        sku: o.sku,
        units: o.units,
        variant_title: o.variant_title,
      })),
    ];
    console.log("reconItems", reconItems);
    const reconPayload = {
      fulfillment_id: orderId,
      line_items: reconItems,
    };
    console.log("recon payload", reconPayload);

    const data = await updateReconProcessedAt(reconPayload); // updateReconProcessedAt function mark damage attribute in recon
    if (data === 500) {
      toast.error("Damaged Marked Unsuccesfull!! Try Again");
      return;
    }
    // console.log(toBeUpdatedBody)
    // console.log(updatedBodyShopify)
    // if (updatedBodyShopify.length > 0) {
    //   dispatch(updateDamagedRtoOrders(updatedBodyShopify, toBeUpdatedBody));
    // } else {
    //   dispatch(replenishDamagedRTOorder(toBeUpdatedBody));
    // }
    dispatch(replenishDamagedRTOorder(toBeUpdatedBody));
    setIsDamagedMarked(true);
    setSearchValue("");
    setSearchAwbValue("");
    handleClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    background: "whitesmoke",
    textAlign: "center",
    maxHeight: "80vh",
    overflowY: "scroll",
    maxWidth: "90%",
  };

  return (
    <div>
      <Modal
        open={openUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3>Select Damaged Items</h3>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <>
                      <Checkbox
                        color="primary"
                        checked={selectedOrders.length > 0 ? true : false}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedOrders(orderProducts);
                          } else {
                            setSelectedOrders([]);
                          }
                        }}
                      />
                      {`${selectedOrders.length} Selected`}
                    </>
                  </TableCell>
                  <TableCell align="left">Image</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">SKU</TableCell>
                  <TableCell align="left">Size</TableCell>
                  <TableCell align="left">Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderProducts.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row" align="left">
                      <Checkbox
                        color="primary"
                        checked={
                          selectedOrders.filter((i) => i.name === row.name)
                            .length > 0
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedOrders([...selectedOrders, row]);
                          } else {
                            setSelectedOrders(
                              selectedOrders.filter(
                                (item) => item.name !== row.name
                              )
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                      <Avatar
                        src={row.url}
                        sx={{
                          width: 100,
                          height: 100,
                          objectFit: "ro",
                        }}
                        variant="square"
                      />
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.sku.split(".")[0]}</TableCell>
                    <TableCell align="left">{row.variant_title}</TableCell>
                    <TableCell align="left">{row.units}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          {/* <Button
            variant="contained"
            color="error"
            disabled={selectedOrders.length === 0}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button> */}
          {isDamagedMarked ? (
            <div>
              <CheckCircleIcon color="success" />
              <Typography variant="body1" color="green">
                Damaged items successfully marked!
              </Typography>
            </div>
          ) : (
            <Button
              variant="contained"
              color="error"
              disabled={selectedOrders.length === 0 || isDamagedMarked}
              onClick={handleSubmit}
            >
              SUBMIT
            </Button>
          )}
        </Box>
      </Modal>
    </div>
  );
};
