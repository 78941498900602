import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
    Box,
    Button,
    Collapse,
    Paper,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
    clearForwardReportData,
    clearReverseReportData,
    getForwardDataByStatusReport,
    getReverseDataByStatusReport,
} from "../../actions/reverseDeliveryActions";
import { useEffect, useState } from "react";
import ReReportTable from "./ReReportTable";
import ReverseAnalyticsChart from "./ReverseAnalyticsChart";
import SecondViewReport from "./reportSecondView/SecondViewReport";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const reverseStatuses = [
    "new",
    "rejected",
    "bsync#RPPickupPending",
    "bsync#RPOutForPickup",
    "R_DLVD",
    "bsync#IT",
    "bsync#RPPickDone",
    "bsync#RAD",
    "bsync#OFD",
    // reverse orders
    "DTO",
    "bsync#PP_Open",
    "bsync#PP_Scheduled",
    "bsync#PP_Dispatched",
    "bsync#PU_In_Transit",
    "bsync#PU_Pending",
    "bsync#PU_Dispatched",
    "bsync#CN_Canceled",
    "bsync#CN_Closed",
];
const ReturnReport = () => {
    const getDateRange = (date) => {
        date.setUTCHours(0, 0, 0, 0);
        return `${date.toISOString().split("T")[0]}T00:00:00+05:30`;
    };
    const requiredReverseFromDate = getDateRange(
        new Date(new Date().setDate(new Date().getDate() - 30))
    );
    const requiredReverseToDate = getDateRange(new Date());

    const [fromValue, setFromValue] = useState(requiredReverseFromDate);
    const [toValue, setToValue] = useState(requiredReverseToDate);
    const [stats, setStats] = useState({
        totalOrders: 0,
        orders: 0,
        approved: 0,
        rejected: 0,
        pending: 0,
        intransit: 0,
        exchange: 0,
        voucher: 0,
    });
    const [openFilter, setOpenFilter] = useState(false);
    const [value, setValue] = useState(0);
    //   const [dateFilterData,setDateFilterData] = useState([])
    //   const [deliveredFilterData,setDeliveredFilterData] = useState([])

    const dispatch = useDispatch();

    const { reverse_by_status_report, forward_by_status_report } = useSelector(
        (state) => state.reverseDetails
    );

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(clearForwardReportData());
        dispatch(clearReverseReportData());
        dispatch(
            getReverseDataByStatusReport({
                p_status: reverseStatuses,
                from_date: fromValue,
                to_date: toValue,
            })
        );

        dispatch(
            getForwardDataByStatusReport({
                p_status: ["DLVD", "Delivered"],
                from_date: fromValue,
                to_date: toValue,
            })
        );
    }, []);

    useEffect(() => {
        setStats((prev) => ({
            ...prev,
            orders: reverse_by_status_report.length,
            approved: reverse_by_status_report.filter(
                (o) => o.package_status !== "rejected"
            ).length,
            rejected: reverse_by_status_report.filter(
                (o) => o.package_status === "rejected"
            ).length,
            pending: reverse_by_status_report.filter(
                (o) =>
                    o.package_status === "bsync#RPPickupPending" ||
                    o.package_status === "bsync#RPOutForPickup" ||
                    o.package_status === "bsync#PP_Open" ||
                    o.package_status === "bsync#PP_Scheduled"
            ).length,
            intransit: reverse_by_status_report.filter(
                (o) =>
                    o.package_status === "bsync#IT" ||
                    o.package_status === "bsync#RPPickDone" ||
                    o.package_status === "bsync#RAD" ||
                    o.package_status === "bsync#OFD" ||
                    o.package_status === "bsync#PP_Dispatched" ||
                    o.package_status === "bsync#PU_In_Transit" ||
                    o.package_status === "bsync#PU_Pending" ||
                    o.package_status === "bsync#PU_Dispatched"
            ).length,
            delivered: reverse_by_status_report.filter(
                (o) =>
                    o.package_status === "R_DLVD" || o.package_status === "DTO"
            ).length,
            exchange: reverse_by_status_report.filter(
                (x) =>
                    (x.exchangeAction === "Replacement" ||
                        x.exchangeAction === "Replacement&Giftcard") &&
                    !!x.new_exchangeOrder_created
            ).length,
            voucher: reverse_by_status_report.filter(
                (x) =>
                    (x.exchangeAction === "Giftcard" ||
                        x.exchangeAction === "Replacement&Giftcard") &&
                    !!x.new_voucher_created
            ).length,
        }));
    }, [reverse_by_status_report, forward_by_status_report]);

    const handleFilterDate = () => {
        dispatch(clearForwardReportData());
        dispatch(clearReverseReportData());

        const fromDate = `${[
            fromValue.$y,
            fromValue.$M <= 9 ? `0${fromValue.$M + 1}` : fromValue.$M,
            fromValue.$D <= 9 ? `0${fromValue.$D}` : fromValue.$D,
        ].join("-")}T00:00:00+05:30`;
        const toDate = `${[
            toValue.$y,
            toValue.$M <= 9 ? `0${toValue.$M + 1}` : toValue.$M,
            toValue.$D <= 9 ? `0${toValue.$D}` : toValue.$D,
        ].join("-")}T00:00:00+05:30`;

        dispatch(
            getReverseDataByStatusReport({
                p_status: reverseStatuses,
                from_date: fromDate,
                to_date: toDate,
            })
        );
        dispatch(
            getForwardDataByStatusReport({
                p_status: ["DLVD", "Delivered"],
                from_date: fromDate,
                to_date: toDate,
            })
        );

        // const filterData = reverse_by_status_report
        //     .filter(x=> x.sync_date>=`${[fromValue.$y,fromValue.$M<=9 ? `0${fromValue.$M + 1}` : fromValue.$M, fromValue.$D<=9 ? `0${fromValue.$D}` : fromValue.$D ].join("-")}T00:00:00+05:30`
        //     && x.sync_date<=`${[toValue.$y,toValue.$M<=9 ? `0${toValue.$M + 1}` : toValue.$M, toValue.$D<=9 ? `0${toValue.$D}` : toValue.$D ].join("-")}T00:00:00+05:30`
        //     )
        // const deliveredFilter = forward_by_status_report
        //     .filter(x=> x.sync_date>=`${[fromValue.$y,fromValue.$M<=9 ? `0${fromValue.$M + 1}` : fromValue.$M, fromValue.$D<=9 ? `0${fromValue.$D}` : fromValue.$D ].join("-")}T00:00:00+05:30`
        //     && x.sync_date<=`${[toValue.$y,toValue.$M<=9 ? `0${toValue.$M + 1}` : toValue.$M, toValue.$D<=9 ? `0${toValue.$D}` : toValue.$D ].join("-")}T00:00:00+05:30`
        //     )
        // setDateFilterData(filterData)
        // setDeliveredFilterData(deliveredFilter)
    };

    return (
        <>
            {reverse_by_status_report.length === 0 ||
            forward_by_status_report.length === 0 ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Skeleton
                        animation="wave"
                        variant="rounded"
                        width={1600}
                        height={200}
                    />
                    <br />
                    <Skeleton
                        animation="wave"
                        variant="rounded"
                        width={500}
                        height={50}
                    />
                    <br />
                    <Stack spacing={1}>
                        {[1, 2, 3, 4, 5].map((x) => (
                            <Skeleton
                                key={x}
                                animation="wave"
                                variant="rounded"
                                width={1600}
                                height={300}
                            />
                        ))}
                    </Stack>
                </Box>
            ) : (
                <div>
                    <Box
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => {
                                setOpenFilter((prev) => !prev);
                                // setDateFilterData([])
                                setFromValue(null);
                                setToValue(null);
                                // setDeliveredFilterData([])
                            }}
                        >
                            {openFilter
                                ? "Clear Date Filter"
                                : "Apply Date Filter"}
                        </Button>
                    </Box>
                    <br />
                    <Collapse in={openFilter}>
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Box>
                                    <Typography variant="subtitle1">
                                        From Date :{" "}
                                    </Typography>
                                    <DatePicker
                                        minDate={new Date("01/02/2023")}
                                        maxDate={new Date()}
                                        value={fromValue}
                                        onChange={(newValue) =>
                                            setFromValue(newValue)
                                        }
                                        renderInput={(props) => (
                                            <TextField
                                                style={{ background: "white" }}
                                                {...props}
                                                size="small"
                                                helperText={null}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box style={{ paddingLeft: "10px" }}>
                                    <Typography variant="subtitle1">
                                        To Date :{" "}
                                    </Typography>
                                    <DatePicker
                                        minDate={new Date("01/02/2023")}
                                        maxDate={new Date()}
                                        value={toValue}
                                        onChange={(newValue) =>
                                            setToValue(newValue)
                                        }
                                        renderInput={(props) => (
                                            <TextField
                                                style={{ background: "white" }}
                                                {...props}
                                                size="small"
                                                helperText={null}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box
                                    style={{
                                        padding: "0px 0px 3px 10px",
                                        alignSelf: "flex-end",
                                    }}
                                >
                                    <Button
                                        disabled={
                                            fromValue === null ||
                                            toValue === null ||
                                            new Date(fromValue) >
                                                new Date(toValue)
                                        }
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleFilterDate}
                                    >
                                        Apply
                                    </Button>
                                </Box>
                            </LocalizationProvider>
                        </Box>
                    </Collapse>
                    <br />
                    <ReverseAnalyticsChart data={reverse_by_status_report} />

                    <Paper
                        sx={{
                            margin: "auto",
                            borderRadius: "15px",
                            maxWidth: "2000px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: "10px",
                            }}
                        >
                            {/* <Typography>Last 30 Days Dat</Typography> */}
                        </Box>
                        <Stack
                            direction={"row"}
                            sx={{
                                padding: "0px 20px 20px 20px",
                                justifyContent: "space-around",
                                // maxWidth:"800px"
                            }}
                        >
                            <Box
                                style={{
                                    background: "#dadafc",
                                    width: "max-content",
                                    borderRadius: "15px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    style={{ padding: "20px 20px 10px 20px" }}
                                    variant="h6"
                                >
                                    Total
                                </Typography>
                                <Typography
                                    style={{
                                        padding: "0px 20px 20px 20px",
                                        color: "black",
                                    }}
                                    variant="h3"
                                >
                                    {stats.orders}
                                </Typography>
                            </Box>
                            <Box
                                style={{
                                    background: "#c2e7c7",
                                    width: "max-content",
                                    borderRadius: "15px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    style={{ padding: "20px 20px 10px 20px" }}
                                    variant="h6"
                                >
                                    Approved
                                </Typography>
                                <Typography
                                    style={{
                                        padding: "0px 20px 20px 20px",
                                        color: "black",
                                    }}
                                    variant="h3"
                                >
                                    {stats.approved}
                                </Typography>
                            </Box>
                            <Box
                                style={{
                                    background: "#c2e7c7",
                                    width: "max-content",
                                    borderRadius: "15px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    style={{ padding: "20px 20px 10px 20px" }}
                                    variant="h6"
                                >
                                    Rejected
                                </Typography>
                                <Typography
                                    style={{
                                        padding: "0px 20px 20px 20px",
                                        color: "black",
                                    }}
                                    variant="h3"
                                >
                                    {stats.rejected}
                                </Typography>
                            </Box>
                            <Box
                                style={{
                                    background: "#c2e7c7",
                                    width: "max-content",
                                    borderRadius: "15px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    style={{ padding: "20px 20px 10px 20px" }}
                                    variant="h6"
                                >
                                    Pickup Pending
                                </Typography>
                                <Typography
                                    style={{
                                        padding: "0px 20px 20px 20px",
                                        color: "black",
                                    }}
                                    variant="h3"
                                >
                                    {stats.pending}
                                </Typography>
                            </Box>
                            <Box
                                style={{
                                    background: "#c2e7c7",
                                    width: "max-content",
                                    borderRadius: "15px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    style={{ padding: "20px 20px 10px 20px" }}
                                    variant="h6"
                                >
                                    In Transit
                                </Typography>
                                <Typography
                                    style={{
                                        padding: "0px 20px 20px 20px",
                                        color: "black",
                                    }}
                                    variant="h3"
                                >
                                    {stats.intransit}
                                </Typography>
                            </Box>
                            <Box
                                style={{
                                    background: "#c2e7c7",
                                    width: "max-content",
                                    borderRadius: "15px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    style={{ padding: "20px 20px 10px 20px" }}
                                    variant="h6"
                                >
                                    Reverse Delivered
                                </Typography>
                                <Typography
                                    style={{
                                        padding: "0px 20px 20px 20px",
                                        color: "black",
                                    }}
                                    variant="h3"
                                >
                                    {stats.delivered}
                                </Typography>
                            </Box>
                            <Box
                                style={{
                                    background: "#c2e7c7",
                                    width: "max-content",
                                    borderRadius: "15px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    style={{ padding: "20px 20px 10px 20px" }}
                                    variant="h6"
                                >
                                    Exchanges
                                </Typography>
                                <Typography
                                    style={{
                                        padding: "0px 20px 20px 20px",
                                        color: "black",
                                    }}
                                    variant="h3"
                                >
                                    {stats.exchange}
                                </Typography>
                            </Box>
                            <Box
                                style={{
                                    background: "#c2e7c7",
                                    width: "max-content",
                                    borderRadius: "15px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    style={{ padding: "20px 20px 10px 20px" }}
                                    variant="h6"
                                >
                                    Vouchers
                                </Typography>
                                <Typography
                                    style={{
                                        padding: "0px 20px 20px 20px",
                                        color: "black",
                                    }}
                                    variant="h3"
                                >
                                    {stats.voucher}
                                </Typography>
                            </Box>
                        </Stack>
                    </Paper>
                    <br />
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label="View 1" {...a11yProps(0)} />
                            <Tab label="View 2" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <ReReportTable
                            tableData={reverse_by_status_report}
                            deliveredOrdersData={forward_by_status_report}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <SecondViewReport
                            tableData={reverse_by_status_report}
                            deliveredOrdersData={forward_by_status_report}
                        />
                    </TabPanel>
                </div>
            )}
        </>
    );
};

export default ReturnReport;
