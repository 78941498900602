import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { Button } from "@mui/material";
import { getDynamoOrdersByDate, allOrders } from "../../actions/ordersActions";
import SearchBar from "../viewOrderByStatus/SearchBar";
import MarchPrepaid from "../../utils/tempOrders.json";
import { useSelector, useDispatch } from "react-redux";

const InvestorReport = () => {
    const [loading, setLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [date, setDate] = useState({ startDate: "", endDate: "" });
    const [orders, setOrders] = useState([]);
    const auth = useSelector((state) => state.auth);
    const { orders: shopifyOrders } = useSelector(
        (state) => state.orderDetails
    );

    // const dispatch = useDispatch();
    // useEffect(() => {
    //     if (shopifyOrders.length === 0) {
    //         dispatch(allOrders());
    //         console.log("Here");
    //     } else {
    //         console.log("ERROR", shopifyOrders);
    //     }
    // }, [dispatch, auth]);
    let csvData = [];
    // AllData.reduce((acc, i) => {
    //     i.items?.forEach((p, index) => {
    //         const item = {
    //             InvoiceNo: i.invoice_number || "",
    //             InvoiceDate: i.invoice_date || "",
    //             OrderNo: i.order_id || "",
    //             PaymentType: i.payment || "",
    //             isRTO: i.isRto ? "YES" : "NO",
    //             isLOST: i.isLost ? "YES" : "NO",
    //             ItemName: p.name || "",
    //             Quantity: p.quantity.toString() || "",
    //             SellingPrice: p.selling_price.toString() || "",
    //             SKU: p.sku || "",
    //             Size: p.variant || "",
    //             Category: p.category || "",
    //             Subcategory: p.subcategory || "",
    //             MRP: p.mrp.toString() || "",
    //             ItemDiscount: p.discount.toString() || "",
    //             CostPrice:
    //                 parseFloat(p.selling_price) < 1 ? "0.00" : p.cost_price,
    //             GSTrate: p.gst_rate || "",
    //             GSTamount: p.gst_amount.toString() || "",
    //             PackagingCost: p.category === "Footwear" ? "15.00" : "2.50",
    //             LabellingCost: i.labelling_cost.toString() || "",
    //             ShippingCharges:
    //                 index === 0 ? i.shipping_charges.toString() : "0.00",
    //             Channel: i.channel || "Online store",
    //             CustomerName: i.customer_name || "",
    //             CustomerId: i.customer_id.toString() || "",
    //             PINCODE: i.customer_pincode || "",
    //             STATE: i.customer_state || "",
    //             CITY: i.customer_city || "",
    //             SecondaryDiscount: i.secondary_discount || "",
    //             SaleDate: i.sale_date || "",
    //             OrderDate: i.order_date || "",
    //             DispatchDate: i.dispatch_date || "",
    //             DeliveredDate: i.delivered_date || "",
    //         };
    //         acc.push(item);
    //     });

    //     return acc;
    // }, []);

    useEffect(() => {
        // const data = MarchPrepaid.reduce((acc, o) => {
        //     // Check fulfillments in March
        //     const marchFulfillments = o.fulfillments.filter(
        //         (f) =>
        //             new Date(f.created_at) <
        //             new Date("2024-04-01T00:00:00+05:30")
        //     );
        //     const removedMarchFulfilledLineItems = o.line_items.filter((l) => {
        //         let keep = true;
        //         marchFulfillments.forEach((f) => {
        //             const found = f.line_items.find((p) => p.id === l.id);
        //             if (!!found) {
        //                 keep = false;
        //             }
        //         });
        //         return keep;
        //     });
        //     o.line_items = removedMarchFulfilledLineItems;
        //     acc.push(o);

        //     return acc;
        // }, []);
        // const removedEmptyLineItemsOrders = data
        //     .filter((o) => o.line_items.length > 0)
        //     .map((o) => ({
        //         order_number: o.order_number,
        //         created_at: o.created_at,
        //         isRefunded: o.isRefunded ? "YES" : "NO",
        //         line_items: o.line_items.map((p) => ({
        //             name: p.name,
        //             sku: p.sku,
        //             quantity: p.quantity,
        //             variant_title: p.variant_title,
        //             price: p.price,
        //         })),
        //     }));

        // console.log("Final", removedEmptyLineItemsOrders);
        // csvData = MarchPrepaid.reduce((acc, i) => {
        //     i.line_items?.forEach((p) => {
        //         const item = {
        //             OrderDate: i.created_at || "",
        //             OrderNo: i.order_number + "" || "",
        //             PaymentType: "PREPAID",
        //             ItemName: p.name || "",
        //             Quantity: p.quantity.toString() || "",
        //             MRP: p.price?.toString() || "",
        //             SKU: p.sku || "",
        //             Size: p.variant_title || "",
        //             Refunded: i.isRefunded,
        //         };
        //         acc.push(item);
        //     });
        //     return acc;
        // }, []);
        console.log("CSV data: ", csvData);
        // if (shopifyOrders.length === 0) {
        //     dispatch(allOrders());
        // }
        if (orders.length > 0) {
            // csvData = orders.reduce((acc, i) => {
            //     i.order_items?.forEach((p, index) => {
            //         const item = {
            //             InvoiceNo: i.awb_code || "",
            //             InvoiceDate: i.order_date?.split("T")[0] || "",
            //             OrderNo: i.original_order_id || "",
            //             PartialFulfillmentNo: i.fulfillment_id + "" || "",
            //             PaymentType: "PREPAID",
            //             FinalStatus: i.package_status || "",
            //             ItemName: p.name || "",
            //             Quantity: p.units.toString() || "",
            //             SellingPrice: p.selling_price?.toString() || "",
            //             SKU: p.sku || "",
            //             Size: p.variant_title || "",
            //             ItemDiscount: p.discount?.toString() || "",
            //             CostPrice:
            //                 parseFloat(p.selling_price) < 1
            //                     ? "0.00"
            //                     : (parseFloat(p.selling_price) * 0.4).toFixed(
            //                           2
            //                       ) || "0.00",
            //             ShippingCharges:
            //                 index === 0
            //                     ? i.shipping_charges.toString()
            //                     : "0.00",
            //             Channel: "Online store",
            //             CustomerName:
            //                 i.shipping_customer_name +
            //                 " " +
            //                 i.shipping_last_name,
            //             CustomerId: i.customer_id.toString() || "",
            //             PINCODE: i.shipping_pincode || "",
            //             STATE: i.shipping_state || "",
            //             CITY: i.shipping_city || "",
            //             SecondaryDiscount: "0.00",
            //             SaleDate: i.order_date?.split("T")[0] || "",
            //             OrderDate: i.order_date?.split("T")[0] || "",
            //             DispatchDate: i.pickedup_date
            //                 ? i.pickedup_date?.split("T")[0]
            //                 : i.processed_at?.split("T")[0],
            //             DeliveredDate: i.delivery_date
            //                 ? i.delivery_date?.split("T")[0]
            //                 : "NA",
            //             RTODeliveredDate: i.rto_delivered_on
            //                 ? i.rto_delivered_on?.split("T")[0]
            //                 : "NA",
            //         };
            //         acc.push(item);
            //     });
            //     return acc;
            // }, []);
            // order_date order_date
            // const octoberOrders = orders.filter(
            //     (o) =>
            //         o.order_date.substring(5, 7) === "10" &&
            //         o.payment_method !== "COD"
            // );
            // console.log(
            //     "OCTOBER PREPAID orders",
            //     octoberOrders.length,
            //     octoberOrders
            // );
            // const undelivered = octoberOrders.filter(
            //     (o) =>
            //         !(
            //             new Date(o.delivery_date) <
            //             new Date("2024-11-01T00:00:00+05:30")
            //         )
            // );
            // console.log(
            //     "OCTOBER undelivered orders",
            //     undelivered.length,
            //     undelivered
            // );
            // const omitRTO = undelivered.filter(
            //     (o) =>
            //         !(
            //             new Date(o.rto_delivered_on) <
            //             new Date("2024-11-01T00:00:00+05:30")
            //         )
            // );
            // console.log(
            //     "OCTOBER undelivered omitting RTO orders",
            //     omitRTO.length,
            //     omitRTO
            // );
            // const requiredData = omitRTO.map((o) => ({
            //     AWB: o.awb_code || "",
            //     PaymentMethod: o.payment_method || "",
            //     OrderNo: o.original_order_id || "",
            //     OrderDate: o.order_date?.split("T")[0] || "",
            //     FulfillmentNo: o.fulfillment_id || "",
            //     ItemSKUs: o.order_items.reduce(
            //         (text, i) => text + i.sku + ", ",
            //         ""
            //     ),
            //     TOTAL: o.total,
            //     CurrentStatus: o.package_status || "",
            //     DeliveryDate: o.delivery_date
            //         ? o.delivery_date.split("T")[0]
            //         : "N/A",
            //     RTODeliveryDate: o.rto_delivered_on
            //         ? o.rto_delivered_on.split("T")[0]
            //         : "NA",
            // }));
            // console.log("DATA", requiredData);
        }
    }, [orders]);

    // useEffect(() => {
    //     const onlyPrepaid = shopifyOrders
    //         .filter(
    //             (o) =>
    //                 !o?.payment_gateway_names?.includes(
    //                     "Cash on Delivery (COD)"
    //                 )
    //         )
    //         .map((o) => ({
    //             payment_gateway_names: o.payment_gateway_names,
    //             order_number: o.order_number,
    //             created_at: o.created_at.split("T")[0],
    //             timestamp: o.created_at,
    //             financial_status: o.financial_status,
    //             shipping: o.shipping_lines?.price,
    //             total_price: o.total_price,
    //             isRefunded: o.refunds.length > 0 ? true : false,
    //             refunds: o.refunds.map((r) => ({
    //                 id: r.id,
    //                 amount: r.transactions.reduce(
    //                     (sum, i) => sum + parseFloat(i.amount),
    //                     0
    //                 ),
    //             })),
    //             fulfillments: o.fulfillments,
    //             line_items: o.line_items,
    //         }))
    //         .filter(
    //             (o) =>
    //                 !(
    //                     o.created_at === "2024-02-29" ||
    //                     o.created_at === "2024-03-30"
    //                 )
    //         );

    //     console.log("All Shopify Prepaid", onlyPrepaid);
    // }, [shopifyOrders]);

    const handleSearch = async () => {
        setIsEmpty(false);
        setLoading(true);
        console.log("date", date);
        const data = await getDynamoOrdersByDate(date);
        if (data.statusCode === 200) {
            setOrders(data.data);
            if (data.data.length <= 0) {
                setIsEmpty(true);
            }
        }
        setLoading(false);
    };

    return (
        <div>
            <ExportReactCSV csvData={csvData} fileName="August2024_NEW" />
            <SearchBar
                handleCourierChange={null}
                orderStatus={[]}
                courier={[]}
                courierSelected={null}
                setDate={setDate}
                handleSearch={handleSearch}
                loading={loading}
                setStatus={null}
                status={null}
                ordersLength={orders.length}
            />
            {isEmpty && (
                <div>
                    <h3>No orders Found</h3>
                </div>
            )}
            {orders.length > 0 && <div>{orders.length} orders fetched</div>}
        </div>
    );
};

const ExportReactCSV = ({ csvData, fileName }) => {
    return (
        <Button variant="contained" color="secondary">
            <CSVLink data={csvData} filename={fileName}>
                Export Excel
            </CSVLink>
        </Button>
    );
};

export default InvestorReport;
