import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField, Stack, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import { gstData } from "../../actions/reportActions";
import { getXBGstData, getXBGstDataForAllOrders } from "../../utils/gstHelpers";
import XBGstTable from "../ordersWidgets/gst/XBGstTable";
import {
  getAllShipRocketOrderDelivered,
  getOrdersByOriginalOrderId,
} from "../../actions/ordersActions";
function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Function to extract the order ID
const extractOrderId = (str) => {
  const match = str.match(/\d+\.\d+/); // Matches numbers with a decimal point
  return match ? match[0] : null; // Returns the matched order ID or null if not found
};

const ShiprocketGst = () => {
  const { deliveredOrders } = useSelector((state) => state.reports);
  const { srDeliveredOrders } = useSelector((state) => state.orderDetails);
  // console.log(excelFile)
  const [monthIndex, setMonthIndex] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  // console.log(monthIndex)
  const dispatch = useDispatch();

  const monthIndexArray = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  useEffect(() => {
    // dispatch(gstData());
    // dispatch(getAllShipRocketOrderDelivered());
  }, []);
  console.log("month index", monthIndex);
  const handleSyncData = async () => {
    const shiprocketRes = await fetch("/shiprocket.json");
    const shiprocketOrders = await shiprocketRes.json();

    const dbRes = await fetch("/db.json");
    const dbOrders = await dbRes.json();

    const excelFileData = [];
    console.log("excel", excelFileData);
    const status = srDeliveredOrders.map((s) => s.status);
    // Create a Set from the array to remove duplicates
    let uniqueStringsSet = new Set(status);

    // Convert the Set back to an array
    let uniqueStringsArray = Array.from(uniqueStringsSet);
    console.log("unique string", uniqueStringsArray);
    //   [
    //     "RETURN PICKUP GENERATED", - rto
    //     "RETURN IN TRANSIT",- rto
    //     "RETURN PICKUP EXCEPTION",-rto
    //     "DELIVERY DELAYED", - intransit
    //     "PICKUP SCHEDULED", -in
    //     "PICKUP RESCHEDULED",-in
    //     "RETURN DELIVERED",-rto
    //     "RETURN CANCELLED", - delivered
    //     "RETURN PICKED UP", - rto
    //     "RETURN PENDING", - delivered
    //     "READY TO SHIP",  - intr
    //     "PICKUP EXCEPTION", - intr
    //     "OUT FOR DELIVERY", - int
    //     "IN TRANSIT",
    //     "DELIVERED",
    //     "CANCELED", -rto
    //     "REACHED AT DESTINATION HUB", -intr
    //     "UNDELIVERED-1ST ATTEMPT", -in
    //     "MISROUTED", -in
    //     "RTO IN TRANSIT",-rti
    //     "UNDELIVERED-2ND ATTEMPT",
    //     "CANCELLATION REQUESTED", -intr
    //     "UNDELIVERED-3RD ATTEMPT",
    //     "UNDELIVERED",-in
    //     "RTO INITIATED",
    //     "RTO DELIVERED",
    //     "NEW" -in
    // ]
    const orders = srDeliveredOrders.filter((so) => so.picked_up_date);
    // const inTransit = orders.filter(
    //   (ec) => {
    //     if (
    //       (ec.status === "NEW" ||
    //         ec.status === "UNDELIVERED" ||
    //         ec.status === "UNDELIVERED-3RD ATTEMPT" ||
    //         ec.status === "CANCELLATION REQUESTED" ||
    //         ec.status === "UNDELIVERED-2ND ATTEMPT" ||
    //         ec.status === "MISROUTED" ||
    //         ec.status === "UNDELIVERED-1ST ATTEMPT" ||
    //         ec.status === "REACHED AT DESTINATION HUB" ||
    //         ec.status === "IN TRANSIT" ||
    //         ec.status === "OUT FOR DELIVERY" ||
    //         ec.status === "PICKUP EXCEPTION" ||
    //         ec.status === "READY TO SHIP" ||
    //         ec.status === "DELIVERY DELAYED" ||
    //         ec.status === "PICKUP SCHEDULED" ||
    //         ec.status === "PICKUP RESCHEDULED") &&
    //       ec.shipments[0].shipped_date
    //     ) {
    //       console.log("row", ec);
    //       const deliveredDate = ec.shipments[0].shipped_date
    //         .split(" ")[0]
    //         .split("-");
    //       const month = deliveredDate[1];
    //       const year = deliveredDate[0];
    //       console.log("month year", month, monthIndex, year);
    //       return month === monthIndex && year === "2024";
    //     } else {
    //       return false;
    //     }
    //   }
    //   // new Date(ec.MANIFESTATIONDATE).getMonth() === monthIndex ||
    // );
    console.log("RTO");
    const RTO = orders.filter((ec) => {
      if (
        ec.status === "CANCELED" ||
        ec.status === "RTO IN TRANSIT" ||
        ec.status === "RTO INITIATED" ||
        ec.status === "RTO DELIVERED"
      ) {
        const deliveredDate = ec.shipments[0].rto_initiated_date
          .split(" ")[0]
          .split("-");
        const month = deliveredDate[1];
        const year = deliveredDate[0];
        console.log("month year", month, monthIndex, year);
        return month === monthIndex && year === "2024";
      } else {
        return false;
      }
    });
    // delivered
    console.log("Delivered");
    // const delivered = orders.filter((ec) => {
    //   if (ec.status === "DELIVERED") {
    //     const deliveredDate = ec.delivered_date.split(" ")[0].split("-");
    //     const month = deliveredDate[1];
    //     const year = deliveredDate[2];
    //     console.log("month year", month, monthIndex, year);
    //     return month === monthIndex && year === "2024";
    //   } else {
    //     return false;
    //   }
    // });
    // dispatch(gstData(temp));
    let finalOrders = [];
    const requiredData = shiprocketOrders.filter((ec) => {
      // const requiredData = orders.filter((ec) => {
      if (ec.picked_up_date) {
        const deliveredDate = ec.picked_up_date.split(" ")[0].split("-");
        const month = deliveredDate[1];
        const year = deliveredDate[0];
        console.log(
          "month year",
          month,
          monthIndex,
          year,
          typeof month,
          typeof monthIndex,
          typeof year
        );
        console.log(month === monthIndex && year === "2024");
        return month === monthIndex && year === "2024";
      } else if (ec.shipments[0].rto_initiated_date) {
        const rtoInitiateDate = ec.shipments[0].rto_initiated_date
          .split(" ")[0]
          .split("-");
        const month = rtoInitiateDate[1];
        const year = rtoInitiateDate[0];
        return month === monthIndex && year === "2024";
      } else {
        return false;
      }
    });
    console.log(
      "required data",
      srDeliveredOrders,
      shiprocketOrders,
      requiredData
    );
    for (let i = 0; i < requiredData.length; i++) {
      console.log("progress", i);
      // await delay(500);
      // const data = await getOrdersByOriginalOrderId(
      //   requiredData[i].channel_order_id.split(".")[0]
      // );
      // if (data.status === 200) {
      const found_order = dbOrders.find(
        // const found_order = data.body.find(
        (d) => requiredData[i].channel_order_id === String(d.fulfillment_id)
      );
      if (found_order) {
        const order = requiredData[i];
        const picked_up_date = order.picked_up_date.split(" ")[0].split("-");
        const pickupMonth = picked_up_date[1];
        const pickupYear = picked_up_date[0];
        // check if rto initiated in same month
        if (
          order.status === "CANCELED" ||
          order.status === "RTO IN TRANSIT" ||
          order.status === "RTO INITIATED" ||
          order.status === "RTO DELIVERED"
        ) {
          // check if same month for pickup and rto
          const rtoInitiateDate = order.shipments[0].rto_initiated_date
            .split(" ")[0]
            .split("-");
          const month = rtoInitiateDate[1];
          const year = rtoInitiateDate[0];

          if (
            month === monthIndex &&
            year === "2024" &&
            pickupMonth === monthIndex &&
            pickupYear === "2024"
          ) {
            const newObj1 = {
              fulfillment_id: found_order.fulfillment_id,
              order_items: found_order.order_items,
              shipping_charges: found_order.shipping_charges,
              billing_state: found_order.billing_state,
              inscanDate: order.picked_up_date,
              total: found_order.total,
              status: order.status,
              delivered_date: order.delivered_date,
              shipping_status: order.status, //
              customer_name: order.customer_name,
              rto_date:
                order.shipments[0].rto_initiated_date === "0000-00-00 00:00:00"
                  ? ""
                  : order.shipments[0].rto_initiated_date,
              awb: `${order.shipments[0].awb}(${found_order.payment})`,
              payment: found_order.payment,
              replacementOrder: String(found_order.tags)
                .toLowerCase()
                .includes("replacement")
                ? extractOrderId(found_order.tags)
                : "",
              order_date: found_order.order_date,
              location: `${found_order.shipping_state}, ${found_order.shipping_city}`,
            };
            const newObj2 = {
              fulfillment_id: found_order.fulfillment_id,
              order_items: found_order.order_items,
              shipping_charges: found_order.shipping_charges,
              billing_state: found_order.billing_state,
              inscanDate: order.picked_up_date,
              total: found_order.total,
              status: "DELIVERED",
              delivered_date: order.delivered_date,
              shipping_status: "DELIVERED", //
              customer_name: order.customer_name,
              rto_date: "",
              awb: `${order.shipments[0].awb}(${found_order.payment})`,
              payment: found_order.payment,
              replacementOrder: String(found_order.tags)
                .toLowerCase()
                .includes("replacement")
                ? extractOrderId(found_order.tags)
                : "",
              order_date: found_order.order_date,
              location: `${found_order.shipping_state}, ${found_order.shipping_city}`,
            };

            finalOrders = [...finalOrders, newObj2, newObj1];
          } else if (
            // check if rto
            month === monthIndex &&
            year === "2024" &&
            pickupMonth !== monthIndex
          ) {
            const newObj1 = {
              fulfillment_id: found_order.fulfillment_id,
              order_items: found_order.order_items,
              shipping_charges: found_order.shipping_charges,
              billing_state: found_order.billing_state,
              inscanDate: order.picked_up_date,
              total: found_order.total,
              status: order.status,
              delivered_date: order.delivered_date,
              shipping_status: order.status, //
              customer_name: order.customer_name,
              rto_date:
                order.shipments[0].rto_initiated_date === "0000-00-00 00:00:00"
                  ? ""
                  : order.shipments[0].rto_initiated_date,
              awb: `${order.shipments[0].awb}(${found_order.payment})`,
              payment: found_order.payment,
              replacementOrder: String(found_order.tags)
                .toLowerCase()
                .includes("replacement")
                ? extractOrderId(found_order.tags)
                : "",
              order_date: found_order.order_date,
              location: `${found_order.shipping_state}, ${found_order.shipping_city}`,
            };
            finalOrders = [...finalOrders, newObj1];
          } else {
            // rto in  different month , assume as intransit for current month
            const newObj = {
              fulfillment_id: found_order.fulfillment_id,
              order_items: found_order.order_items,
              shipping_charges: found_order.shipping_charges,
              billing_state: found_order.billing_state,
              inscanDate: order.picked_up_date,
              total: found_order.total,
              status: "DELIVERED",
              delivered_date: order.delivered_date,
              shipping_status: "DELIVERED", //
              customer_name: order.customer_name,
              rto_date: "",
              awb: `${order.shipments[0].awb}(${found_order.payment})`,
              payment: found_order.payment,
              replacementOrder: String(found_order.tags)
                .toLowerCase()
                .includes("replacement")
                ? extractOrderId(found_order.tags)
                : "",
              order_date: found_order.order_date,
              location: `${found_order.shipping_state}, ${found_order.shipping_city}`,
            };
            finalOrders = [...finalOrders, newObj];
          }
        } else {
          const newObj = {
            fulfillment_id: found_order.fulfillment_id,
            order_items: found_order.order_items,
            shipping_charges: found_order.shipping_charges,
            billing_state: found_order.billing_state,
            inscanDate: order.picked_up_date,
            total: found_order.total,
            status: order.status,
            delivered_date: order.delivered_date,
            shipping_date: order.shipments[0].shipped_date, //
            shipping_status: order.status, //
            customer_name: order.customer_name,
            rto_date:
              order.shipments[0].rto_initiated_date === "0000-00-00 00:00:00"
                ? ""
                : order.shipments[0].rto_initiated_date,
            awb: `${order.shipments[0].awb}(${found_order.payment})`,
            payment: found_order.payment,
            replacementOrder: String(found_order.tags)
              .toLowerCase()
              .includes("replacement")
              ? extractOrderId(found_order.tags)
              : "",
            order_date: found_order.order_date,
            location: `${found_order.shipping_state}, ${found_order.shipping_city}`,
          };
          finalOrders = [...finalOrders, newObj];
        }
      } else {
        console.log("not found", requiredData[i]);
      }
      // } else {
      //   console.log("not found", requiredData[i]);
      // }
    }

    console.log("final orders", finalOrders);
    const finalOrdersSorted = finalOrders.sort(
      (a, b) => parseInt(a.awb) - parseInt(b.awb)
    );
    const gstCalculatedData = getXBGstDataForAllOrders(finalOrdersSorted);
    console.log(gstCalculatedData);
    setFilteredOrders(gstCalculatedData);
  };

  return (
    <div>
      <div>
        <h3>Shiprocket GST</h3>
        <div>
          <TextField
            style={{ width: "300px" }}
            select
            label="Select Month"
            value={monthIndex}
            onChange={(e) => {
              setMonthIndex(e.target.value);
            }}
            variant="outlined"
            className="select"
          >
            {monthIndexArray.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <Button variant="contained" color="secondary" onClick={handleSyncData}>
          Sync Data
        </Button>
        <br />
        <XBGstTable gstData={filteredOrders} />
      </div>
      <br />
    </div>
  );
};

export default ShiprocketGst;
